import { ReactNode } from 'react';

import HeaderPage from '../HeaderPage';

interface PageBodyProps {
  children: ReactNode;
  title: string;
  subtitle?: string;
  goBack?: boolean;
  goBackTo?: string;
  marginTopButton?: string;
}

export default function PageBody({
  children,
  title,
  subtitle,
  goBack,
  goBackTo,
  marginTopButton = '0',
}: PageBodyProps) {
  return (
    <>
      <HeaderPage
        title={title}
        subtitle={subtitle}
        goBack={goBack}
        goBackTo={goBackTo}
        marginButtonTop={marginTopButton}
      />
      {children}
    </>
  );
}
