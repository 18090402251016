import styled from 'styled-components';
import colors from '../../styles/colors';

interface InputProps {
  width: string;
  height?: string;
  childrenProps: boolean;
}

export const Container = styled.div<InputProps>`
  width: ${p => p.width};
  height: ${p => p.height};
  padding: 0.3rem 0.75rem;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      line-height: 0.75rem;
      color: ${colors.dark_blue};
      font-size: 0.75rem;

      > span {
        width: 100%;
      }

      > input {
        width: ${p => (p.childrenProps ? 'calc(100% - 34px)' : '100%')};
        border: 0;
        font-size: 0.87rem;
        line-height: 1.25rem;
        color: #0a0a0a;
        padding: 0.5rem 0.5rem;
        background: ${colors.inputs.background};
        border-radius: 4px;

        ::placeholder {
          color: #9e9e9e;
        }

        :read-only {
          color: #777777;
          cursor: not-allowed;
        }
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;

        svg {
          width: 30px;
        }
      }
    }
  }
`;

export const ErrorMessage = styled.small`
  font-size: 10px;
  min-height: 13px;
  color: ${colors.inputs.errorMessage};
`;
