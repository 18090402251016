import { forwardRef, LegacyRef, SelectHTMLAttributes } from 'react';
import { Container, ErrorMessage } from './styles';

type SelectOption = {
  label: string | number;
  value: string | number;
};

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  errorMessage?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  multiple?: boolean;
  name?: string;
  required?: boolean;
  options: SelectOption[];
  width?: string;
  defaultValue?: string;
  hideError?: boolean;
}

const Select = forwardRef(
  (
    {
      label,
      width = '100%',
      errorMessage,
      options,
      defaultValue,
      hideError = false,
      ...rest
    }: SelectProps,
    ref: LegacyRef<HTMLSelectElement>,
  ) => {
    return (
      <Container width={width}>
        <div>
          <label>
            <span>{label}</span>
            <select ref={ref} defaultValue={defaultValue} {...rest}>
              {options.map(opt => (
                <option key={opt.value.toString()} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          </label>
          {!hideError && (
            <ErrorMessage>{errorMessage && errorMessage}</ErrorMessage>
          )}
        </div>
      </Container>
    );
  },
);

export default Select;
