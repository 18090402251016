import React, { TableHTMLAttributes } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { FiDatabase, FiEdit } from 'react-icons/fi';
import { RiSlideshowLine } from 'react-icons/ri';
import { Column, useGlobalFilter, usePagination, useTable } from 'react-table';
import GlobalFilter from './GlobalFilter';
import PaginationTableServerSide from './PaginationServerSide';
import PaginationTable from './PaginationTable';
import {
  DeleteAction,
  Deleted,
  DivTable,
  Edit,
  HeaderContent,
  Report,
  ReportIcon,
  TablePrimal,
  Tags,
  Tbody,
  Thead,
} from './styles';

interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  tabIndex: number;
  columns: Column<any>[];
  data: any[];
  onClick?: (args0: any) => void;
  onDelete?: (args0: any) => void;
  onTag?: (args0: any) => void;
  onEdit?: (args0: any) => void;
  valueTag?: (value: any) => boolean;
  thisPage?: number;
  totalItem?: number;
  isManual?: boolean;
  setPage?: (value: number) => void;
  onReport?: (args0: any) => void;
  pageLimit?: number;
  quickFilter?: boolean;
}

export default function Table({
  tabIndex,
  columns,
  data,
  onClick,
  onDelete,
  thisPage,
  onEdit,
  onTag,
  onReport,
  valueTag,
  totalItem,
  isManual,
  setPage,
  children,
  pageLimit = 5,
  quickFilter = true,
}: TableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    pageCount,
    state: { pageIndex, pageSize, globalFilter },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: pageLimit },
      manualPagination: isManual,
      pageCount: Math.ceil(totalItem / pageLimit),
    },
    useGlobalFilter,
    usePagination,
  );

  const handlePage = ({ n, add }: { n: number; add: boolean }) => {
    setPage(add ? n + 1 : n - 1);
  };

  const handleClickDelete = async (
    event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  const handleSearch = () => {
    if (quickFilter) {
      return (
        <HeaderContent>
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          {children}
        </HeaderContent>
      );
    }
    return null;
  };

  return (
    <>
      {handleSearch()}
      <DivTable>
        {data.length === 0 ? (
          <TablePrimal>
            <Thead>
              <tr>
                <th>Dados</th>
              </tr>
            </Thead>
            <Tbody>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '40vh',
                }}
              >
                <FiDatabase size={100} />
                <span>Sem informações</span>
              </div>
            </Tbody>
          </TablePrimal>
        ) : (
          <TablePrimal {...getTableProps()} tabIndex={tabIndex}>
            <Thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => onClick(row.original)}
                  >
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={(cell.column as any).style}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}

                    {onEdit && (
                      <DeleteAction
                        {...(row.id as any)}
                        aria-hidden="true"
                        onClick={e => handleClickDelete(e)}
                        onClickCapture={
                          onEdit ? () => onEdit(row.original) : undefined
                        }
                      >
                        <Edit>
                          <FiEdit size={20} />
                        </Edit>
                      </DeleteAction>
                    )}
                    {onTag && (
                      <DeleteAction
                        {...(row.id as any)}
                        aria-hidden="true"
                        onClick={e => handleClickDelete(e)}
                      >
                        <Tags>
                          <input
                            onClick={
                              onTag ? () => onTag(row.original) : undefined
                            }
                            type="checkbox"
                            defaultChecked={
                              valueTag ? valueTag(row.original) : false
                            }
                          />
                        </Tags>
                      </DeleteAction>
                    )}
                    {onDelete && (
                      <DeleteAction
                        {...(row.id as any)}
                        aria-hidden="true"
                        onClick={e => handleClickDelete(e)}
                        onClickCapture={
                          onDelete ? () => onDelete(row.original) : undefined
                        }
                      >
                        <Deleted>
                          <FaTrashAlt size={20} />
                        </Deleted>
                      </DeleteAction>
                    )}
                    {onReport && (
                      <Report
                        {...(row.id as any)}
                        aria-hidden="true"
                        onClick={e => handleClickDelete(e)}
                        onClickCapture={
                          onReport ? () => onReport(row.original) : undefined
                        }
                      >
                        <ReportIcon>
                          <RiSlideshowLine size={20} />
                        </ReportIcon>
                      </Report>
                    )}
                  </tr>
                );
              })}
            </Tbody>
          </TablePrimal>
        )}
      </DivTable>
      {!isManual ? (
        <PaginationTable
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageSize={pageSize}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          setPageSize={setPageSize}
        />
      ) : (
        <PaginationTableServerSide
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          pageCount={pageCount}
          onPage={handlePage}
          pageOptions={pageOptions}
          currentPage={thisPage}
          setPage={setPage}
        />
      )}
    </>
  );
}
