import { FaRegArrowAltCircleLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { HeaderActions, HeaderSubtitle, HeaderTitle, UserData } from './styles';

interface HeaderPageProps {
  title: string;
  subtitle?: string;
  marginButtonTop?: string;
  goBack?: boolean;
  goBackTo?: string;
}

export default function HeaderPage({
  title,
  subtitle,
  marginButtonTop,
  goBack = false,
  goBackTo,
}: HeaderPageProps) {
  const navigate = useNavigate();

  return (
    <HeaderActions marginTop={marginButtonTop}>
      {goBack && (
        <button
          type="button"
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            goBackTo ? navigate(goBackTo) : navigate(-1);
          }}
        >
          <FaRegArrowAltCircleLeft size={32} color="#fff" />
        </button>
      )}

      <UserData>
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderSubtitle>{subtitle}</HeaderSubtitle>
      </UserData>
    </HeaderActions>
  );
}
