import styled from 'styled-components';

interface ContainerProps {
  width: string;
  variantText?: boolean;
  margin?: string;
  withoutPadding?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: ${props => props.width};
  display: flex;
  padding: ${props => !props.withoutPadding && '0.3rem 0.75rem'};
  margin: ${props => props.margin};
`;
