import styled from 'styled-components';
import colors from '../../styles/colors';

interface SizeModalProps {
  width: string;
  height: string;
  maxWidth?: string;
}

export const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
`;

export const ContainerAround = styled.div<SizeModalProps>`
  display: flex;
  flex-direction: column;
  width: ${p => p.width};
  height: ${p => (p.height ? p.height : 'auto')};
  max-width: ${p => p.maxWidth};
  background: #fff;

  border-radius: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  max-height: 95vh;
  padding-bottom: 1rem;
`;

export const FormModal = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.p`
  padding: 2rem 2rem 1rem;
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  gap: 1rem;
  margin-right: 2rem;

  svg {
    color: ${colors.red_light};
    cursor: pointer;
    transition: all 0.2s;

    :hover {
      transition: all 0.3s ease-in;
      color: ${colors.red_strong};
    }
  }
`;

export const ConfirmButton = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const ButtonTransparent = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  color: ${colors.blue_light};
  transition: all 0.2s ease-in;

  svg {
    margin: 0 0.5rem;
    transition: all 0.2s ease-in;
  }

  &:hover {
    color: ${colors.dark_blue};

    svg {
      fill: ${colors.dark_blue};
    }
  }
`;

export const FooterTwo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;
