import getAPIClient from './axios';

const api = getAPIClient();

export default api;

interface ApiRequest {
  signal?: AbortSignal;
}

export interface ApiGETRequest<T = undefined> extends ApiRequest {
  params?: T;
}

export interface ApiDELETERequest<T = undefined> extends ApiRequest {
  params?: T;
}

export interface ApiPOSTRequest<T = undefined> extends ApiRequest {
  body?: T;
}

export interface ApiPUTRequest<T = undefined, K = undefined>
  extends ApiRequest {
  params?: T;
  body?: K;
}

export interface ApiPATCHRequest<T = undefined, K = undefined>
  extends ApiRequest {
  params?: T;
  body?: K;
}

export interface Listing<T = unknown> {
  total: number;
  list: T[];
}

export interface Pagination {
  page: number | string;
  limit: number | string;
}

export interface APIResponse<T = undefined> {
  error: number;
  statusCode: number;
  message: string;
  data?: T;
}
