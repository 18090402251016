export default function parseNumberFromBRL(value: string): number {
  if (value.length <= 0) {
    return 0;
  }
  const valueFormatted = value
    .replaceAll('.', '')
    .replaceAll(',', '.')
    .replace('R$', '');
  return Number(valueFormatted);
}
