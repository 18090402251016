import api, {
  ApiDELETERequest,
  ApiGETRequest,
  ApiPATCHRequest,
  ApiPOSTRequest,
  ApiPUTRequest,
  APIResponse,
} from '../Api/api';
import {
  AddDocumentByEquipment,
  CreateEquipment,
  DocumentByEquipment,
  Equipment,
  GetMachineDowntimeParams,
  ListMachine,
  MachineQualitative,
  Response,
  ResponseImport,
  ReturnMachineByID,
  ReturnMachineStopped,
  ReturnMachineTimeStopped,
  ReturnPlannedMaintenance,
  UpdateMachineManualBody,
} from './types';

export async function getEquipment({
  signal,
  params,
}: ApiGETRequest<{
  description: string;
  unit_id: string;
  status: string;
  loc_id: number | string;
  mac_plate: string;
  mac_numeration: string;
}>): Promise<APIResponse<Equipment[]>> {
  const res = await api
    .get(
      `/machine?description=${params.description}&unit_id=${params.unit_id}&status=${params.status}&loc_id=${params.loc_id}&mac_numeration=${params.mac_numeration}&mac_plate=${params.mac_plate}`,
      { signal },
    )
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function getEquipmentByChip({
  signal,
}: ApiGETRequest): Promise<APIResponse<ListMachine[]>> {
  const res = await api
    .get('/machineChip/1', { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function getEquipmentByCheckListQualitative({
  params,
  signal,
}: ApiGETRequest<{ id: number }>): Promise<APIResponse<MachineQualitative[]>> {
  const res = await api
    .get(`/machine/byChecklist/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function getEquipmentByID({
  signal,
  params,
}: ApiGETRequest<{ id: string }>): Promise<APIResponse<ReturnMachineByID>> {
  const res = await api
    .get(`/machine/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function getEquipmentPlannedMaintenanceByChip({
  signal,
  params,
}: ApiGETRequest<{ id: string }>): Promise<
  APIResponse<ReturnPlannedMaintenance[]>
> {
  const res = await api
    .get(`/maintenanceSchedule/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function getEquipmentTimeStopped({
  signal,
  params,
}: ApiGETRequest<{ id: string }>): Promise<
  APIResponse<ReturnMachineTimeStopped>
> {
  const res = await api
    .get(`/registerMaintenance/timeStoppedByMachine/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function getEquipmentByCompany({
  params,
  signal,
}: ApiGETRequest<{ id: string }>): Promise<APIResponse<Equipment[]>> {
  const res = await api
    .get(`/machine/byCompany/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function getEquipmentStopped({
  params,
  signal,
}: ApiGETRequest<GetMachineDowntimeParams>): Promise<
  APIResponse<ReturnMachineStopped>
> {
  const res = await api
    .get(`/machine/filterByDate/${params.initialDate}/${params.finalDate}`, {
      signal,
    })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function createEquipment({
  signal,
  body,
}: ApiPOSTRequest<CreateEquipment>): Promise<APIResponse<Response>> {
  const res = await api
    .post('machine/', body, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function importExcelEquipment(
  file: File,
  config?: unknown,
): Promise<APIResponse<ResponseImport[]>> {
  const form = new FormData();
  form.append('file', file);

  const res = await api
    .post('machine/machineImport/import', form, config)
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function updateEquipment({
  signal,
  body,
}: ApiPUTRequest<undefined, CreateEquipment>): Promise<APIResponse<Response>> {
  const res = await api
    .put('machine/', body, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function deleteEquipment({
  signal,
  params,
}: ApiDELETERequest<{ data: string }>): Promise<APIResponse<Response>> {
  const res = await api
    .delete(`machine/${params.data}`, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function getDocumentByEquipment({
  signal,
  params,
}: ApiGETRequest<{
  dataParam: string;
}>): Promise<APIResponse<DocumentByEquipment[]>> {
  const res = await api
    .get(`machineDocuments/byMachine/${params.dataParam}`, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function createDocumentByEquipment({
  signal,
  body,
}: ApiPOSTRequest<AddDocumentByEquipment>): Promise<
  APIResponse<DocumentByEquipment[]>
> {
  const res = await api
    .post('machineDocuments/', body, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function updateDocumentByEquipment({
  signal,
  body,
  params,
}: ApiPUTRequest<{ id: string }, AddDocumentByEquipment>): Promise<
  APIResponse<DocumentByEquipment[]>
> {
  const res = await api
    .put(`machineDocuments/${params.id}`, body, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function updateMachineManual({
  signal,
  body,
}: ApiPATCHRequest<{}, UpdateMachineManualBody>): Promise<
  APIResponse<DocumentByEquipment[]>
> {
  const res = await api
    .patch(`machineDocuments`, body, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function deleteDocumentByEquipment({
  signal,
  params,
}: ApiDELETERequest<{ id: string }>): Promise<
  APIResponse<DocumentByEquipment[]>
> {
  const res = await api
    .delete(`machineDocuments/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}
