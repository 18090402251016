import {
  Box,
  Card,
  CardContent,
  CardHeader,
  colors,
  Divider,
  useTheme,
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

interface DowntimeBarChartProps {
  firstValue: number;
  secondValue: number;
}

export default function DowntimeBarChart({
  firstValue,
  secondValue,
}: DowntimeBarChartProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const valuePercentOne = Math.round(firstValue);
  const valuePercentTwo = Math.round(secondValue);

  const data = {
    datasets: [
      {
        backgroundColor: [colors.lightBlue[400], colors.deepPurple[900]],
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: [valuePercentOne, valuePercentTwo],
        label: t('maintenances-comparison'),
        maxBarThickness: 10,
      },
    ],
    labels: [
      t('maintenances-opened.first-table'),
      t('maintenances-opened.second-table'),
    ],
  };

  const options = {
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card style={{ height: '100%', width: '100%', marginTop: '50px' }}>
      <CardHeader
        disableTypography
        title={t('maintenances-opened.title-graphic')}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: '400px',
            position: 'relative',
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
}
