import { colors } from '@mui/material';
import styled from 'styled-components';

export const Space = styled.div`
  height: 20px;
`;

export const AdditionalInfoContainer = styled.div`
  min-height: 20px;
  padding: 0 0 1rem 0;
`;

export const ContentFirst = styled.div`
  button {
    background: ${colors.deepPurple[900]};
    :hover {
      background: ${colors.deepPurple[700]};
    }
  }
`;

export const ContentTwo = styled.div`
  button {
    background: ${colors.red[700]};
    :hover {
      background: ${colors.red[600]};
    }
  }
`;
