import { HTMLAttributes } from 'react';
import * as S from './styles';
import CardLoader from '../CardLoader';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  hoverAnimation?: boolean;
  clickable?: boolean;
  loading?: boolean;
}

export default function Card({
  children,
  style,
  hoverAnimation = false,
  clickable = false,
  loading = false,
  onClick,
}: CardProps) {
  return (
    <S.Card
      hoverAnimation={hoverAnimation}
      clickable={clickable}
      style={style}
      onClick={onClick}
    >
      {loading ? <CardLoader /> : children}
    </S.Card>
  );
}
