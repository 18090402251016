import { Grid } from '@mui/material';

import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components';

import { Container, ContainerChildren } from '../components/PageBody/styles';
import Sidebar from '../components/SideBar';
import './layout.css';

interface AppLayoutProps {
  withPadding?: boolean;
}

export default function AppLayout({ withPadding = false }: AppLayoutProps) {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <Container>
      <Grid width="100%" height="100%">
        <Sidebar navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
        <Header setNavbarOpen={setNavbarOpen} />

        <div className="app-layout_gradient" />
        <ContainerChildren hasPadding={withPadding}>
          <Outlet />
        </ContainerChildren>
      </Grid>
    </Container>
  );
}
