import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    display: none;
  }
  canvas {
    max-height: 500px;
    max-width: 800px;
  }
`;

export const WrapperPrinButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

export const WrapperLoad = styled.div`
  width: 300px;
  height: 200px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  p {
    font-size: 1rem;
    margin-top: 10px;
  }

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -71px;
    background: white;
    filter: contrast(20);

    > div {
      transform: translateX(0);
      margin-top: 12px;
      margin-left: 31px;
      animation: dots 2.8s infinite;
      span {
        display: block;
        float: left;
        width: 16px;
        height: 16px;
        margin-left: 16px;
        filter: blur(4px);
        background: #000;
        border-radius: 50%;
      }
    }
    section {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 12px;
      left: 15px;
      filter: blur(4px);
      background: #000;
      border-radius: 50%;
      transform: translateX(0);
      animation: dot 2.8s infinite;
    }
  }

  @keyframes dot {
    50% {
      transform: translateX(96px);
    }
  }
  @keyframes dots {
    50% {
      transform: translateX(-31px);
    }
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-top: 2rem;
  width: 100%;
  > b {
    margin-bottom: 0.7rem;
  }

  > p {
    margin-left: 1rem;
    font-size: 14px;
  }
  p:last-child {
    margin-bottom: 0.7rem;
  }

  > div {
    padding: 0;
  }
`;

export const WrapperError = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  p {
    margin: 0.7rem 0 1.7rem;
  }
`;
