import { useTranslation } from 'react-i18next';
import {
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsChevronLeft,
  BsChevronRight,
} from 'react-icons/bs';
import { WrapperPagination } from './styles';

interface PaginationProps {
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  canPreviousPage: boolean;
  previousPage: () => void;
  nextPage: () => void;
  canNextPage: boolean;
  pageSize: number;
  pageCount: number;
  pageIndex: number;
  pageOptions: number[];
  setPageSize: (pageSize: number) => void;
}

export default function PaginationTable({
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageSize,
  pageCount,
  pageIndex,
  pageOptions,
  setPageSize,
}: PaginationProps) {
  const { t } = useTranslation();

  return (
    <WrapperPagination>
      <button
        type="button"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        <BsChevronDoubleLeft />
      </button>
      <button type="button" onClick={previousPage} disabled={!canPreviousPage}>
        <BsChevronLeft />
      </button>
      <button
        type="button"
        onClick={() => {
          nextPage();
        }}
        disabled={!canNextPage}
      >
        <BsChevronRight />
      </button>{' '}
      <button
        type="button"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        <BsChevronDoubleRight />
      </button>{' '}
      <span>
        {t('table-page-page')}{' '}
        <strong>
          {pageIndex + 1} {t('table-page-of')} {pageOptions.length}
        </strong>{' '}
      </span>
      <span>
        | {t('table-page-go-to-page')}:{' '}
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          style={{ width: '100px' }}
        />
      </span>{' '}
      <select
        value={pageSize}
        onChange={e => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[5, 10, 15, 25, 50, 100].map(pageSized => {
          return (
            <option key={pageSized} value={pageSized}>
              {t('table-page-show')} {pageSized}
            </option>
          );
        })}
      </select>
    </WrapperPagination>
  );
}
