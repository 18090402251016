import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlayCircle } from 'react-icons/bs';
import { Container, FileInfo, Preview } from './styles';

interface FileListProps {
  files: any;
  closed: boolean;
  onDelete: () => void;
  onClick?: (file: File) => void;
}

export default function FileList({
  files,
  onDelete,
  closed,
  onClick,
}: FileListProps) {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onClick?.(files);
  }, [onClick, files]);

  const HandlePreview = useMemo(() => {
    if (!files) return null;

    if (typeof files === 'string') {
      if (new RegExp(/\.(mp4|webm|ogg)\?/).test(files))
        return (
          <Preview onClick={handleClick}>
            <BsPlayCircle />
          </Preview>
        );
      return <Preview src={files} onClick={handleClick} />;
    }

    if (files.name) {
      if (new RegExp(/\.(mp4|webm|ogg)$/).test(files.name)) {
        return (
          <Preview onClick={handleClick}>
            <BsPlayCircle />
          </Preview>
        );
      }
    }

    return (
      <Preview
        src={files.preview ? files.preview : files}
        onClick={handleClick}
      />
    );
  }, [files, handleClick]);

  return (
    <Container>
      <li>
        <FileInfo>
          <div>{HandlePreview}</div>
          <span>
            {files.readableSize}
            {!closed && (
              <button type="button" onClick={() => onDelete()}>
                {t('image-delete')}
              </button>
            )}
          </span>
        </FileInfo>
      </li>
    </Container>
  );
}
