import { CircularProgress, colors, Button as MUIButton } from '@mui/material';
import {
  ButtonHTMLAttributes,
  CSSProperties,
  PointerEvent,
  ReactElement,
  ReactNode,
} from 'react';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  loading?: boolean;
  width?: string;
  icon?: ReactElement;
  type?: 'button' | 'submit' | 'reset';
  variantText?: boolean;
  disabledButton?: boolean;
  onClick?: (event: PointerEvent<HTMLButtonElement>) => void;
  margin?: string;
  style?: any;
  wrapperStyle?: CSSProperties;
  withoutPadding?: boolean;
  startIcon?: boolean;
}

export default function Button({
  children,
  width = '100%',
  type,
  onClick,
  variantText,
  icon,
  startIcon,
  loading = false,
  disabledButton = false,
  margin,
  style,
  wrapperStyle,
  withoutPadding,
}: ButtonProps) {
  return (
    <Container
      width={width}
      variantText={variantText}
      margin={margin}
      style={wrapperStyle}
      withoutPadding={withoutPadding}
    >
      <MUIButton
        sx={
          !variantText
            ? {
                width: '100%',
                color: colors.deepPurple[50],
                backgroundColor: colors.deepPurple[900],
                fontFamily: 'Montserrat, Arial, Helvetica, sans-serif',
                fontWeight: 600,
                textTransform: 'none',
                '&:hover': {
                  background: colors.deepPurple[700],
                },
                '&:disabled': {
                  backgroundColor: colors.grey[400],
                  color: colors.grey[50],
                  cursor: 'not-allowed',
                },
                ...style,
              }
            : {
                width: '100%',
                height: 35,
                color: '#222222',
                fontFamily: 'Montserrat, Arial, Helvetica, sans-serif',
                fontWeight: 600,
                textTransform: 'none',
                '&:hover': {
                  background: '#ede7f6',
                },
                ...style,
              }
        }
        onClick={onClick}
        type={type}
        disabled={disabledButton}
        endIcon={startIcon ? '' : icon}
        startIcon={startIcon ? icon : ''}
      >
        {!loading ? (
          <>{children}</>
        ) : (
          <CircularProgress
            size={25}
            sx={{
              color: 'white',
            }}
          />
        )}
      </MUIButton>
    </Container>
  );
}
