import { TFunction } from 'react-i18next';

export function formatFrequency(
  frequency: string,
  interval: number,
  t: TFunction<'translation', undefined>,
) {
  const plural = interval > 1;

  let result = '';

  if (plural) {
    switch (frequency) {
      case 'day':
        result = t('day');
        break;
      case 'week':
        result = t('week');
        break;
      case 'month':
        result = t('month');
        break;
      case 'year':
        result = t('year');
        break;
      default:
        break;
    }
  } else {
    switch (frequency) {
      case 'day':
        result = t('day');
        break;
      case 'week':
        result = t('week');
        break;
      case 'month':
        result = t('month');
        break;
      case 'year':
        result = t('year');
        break;
      default:
        break;
    }
  }

  return ` ${interval} ${result.toLowerCase()}`;
}
