import api, {
  ApiDELETERequest,
  ApiGETRequest,
  ApiPOSTRequest,
  ApiPUTRequest,
  APIResponse,
} from '../Api/api';
import {
  CreateMaintenance,
  CreatePlannedMaintenance,
  DataMaintenancePlanned,
  ReturnCreatePlannedMaintenance,
  ReturnUpdatePlannedMaintenance,
  Maintenance,
  ReturnMaintenance,
  MaintenanceType,
} from './types';

export async function getMaintenanceByChip({
  params,
  signal,
}: ApiGETRequest<{ id: string }>): Promise<APIResponse<Maintenance[]>> {
  const res = await api.get(`/registerMaintenance/byChip/${params.id}`, {
    signal,
  });
  return res.data;
}

export async function getMaintenanceByCompany({
  params,
  signal,
}: ApiGETRequest<{ id: string }>): Promise<APIResponse<Maintenance[]>> {
  const res = await api
    .get(`/registerMaintenance/byCmp/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getMaintenanceTypes({
  signal,
}: ApiGETRequest): Promise<APIResponse<MaintenanceType[]>> {
  const res = await api
    .get(`/typeMaintenance/`, { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getNextMaintenance({
  signal,
}: ApiGETRequest): Promise<APIResponse<DataMaintenancePlanned[]>> {
  const res = await api
    .get('maintenanceSchedule/listAll/Maintenance', { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getMaintenance(): Promise<
  APIResponse<ReturnMaintenance>
> {
  const res = await api
    .get('/registerMaintenance/')
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getMaintenanceOpened({
  signal,
}: ApiGETRequest): Promise<APIResponse<Maintenance[]>> {
  const res = await api
    .get('/registerMaintenance/oppenedMaintenance', { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getMaintenanceById(
  id: string,
): Promise<APIResponse<Maintenance>> {
  const res = await api
    .get(`/registerMaintenance/byId/${id}`)
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getMaintenanceByIdOpened(
  id: string,
): Promise<APIResponse<Maintenance[]>> {
  const res = await api
    .get(`registerMaintenance/oppenedMaintenanceByChip/${id}`)
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function createMaintenance(
  dataParam: CreateMaintenance | FormData,
): Promise<APIResponse<Maintenance>> {
  const res = await api
    .post('/registerMaintenance/', dataParam, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function updateMaintenance(
  dataParam: CreateMaintenance | FormData,
): Promise<APIResponse<ReturnMaintenance>> {
  if (dataParam instanceof FormData) {
    const res = await api
      .put('/registerMaintenance/', dataParam, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(response => response)
      .catch(err => err.response);
    return res.data;
  }

  const res = await api
    .put('/registerMaintenance/', dataParam)
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function closeMaintenance(
  id: number,
  status: number,
): Promise<APIResponse<ReturnMaintenance>> {
  const res = await api
    .put(`/registerMaintenance/closeMaintenance/${id}`, {
      mtc_machine_status: status,
    })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function createPlannedPrevMaintenance({
  signal,
  body,
}: ApiPOSTRequest<CreatePlannedMaintenance>): Promise<
  APIResponse<ReturnCreatePlannedMaintenance>
> {
  const res = await api
    .post('/maintenanceSchedule/', body, { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function updatePlannedPrevMaintenance({
  signal,
  body,
}: ApiPUTRequest<undefined, CreatePlannedMaintenance>): Promise<
  APIResponse<ReturnUpdatePlannedMaintenance>
> {
  const res = await api
    .put('/maintenanceSchedule/', body, { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function deletePlannedPrevMaintenance({
  signal,
  params,
}: ApiDELETERequest<{
  date: string;
  id: string;
}>): Promise<APIResponse> {
  const res = await api
    .delete(`/maintenanceSchedule/${params.id}/${params.date}`, { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}
