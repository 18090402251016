import cep from 'cep-promise';
import { Address } from '../../interfaces';

interface GetCepDataResponse {
  error: boolean;
  data?: Address;
}

/** Busca informações do endereço de um CEP */
export default async function getAddressFromCEP(
  cepString: string,
): Promise<GetCepDataResponse> {
  try {
    const response = await cep(cepString);

    return { error: false, data: response };
  } catch (error) {
    return { error: true };
  }
}
