import api, { ApiGETRequest, APIResponse, Listing } from '../Api/api';
import { Chip, CompleteChip, GetChip, GetChipParams } from './types';

export async function getChips({
  params,
  signal,
}: ApiGETRequest<GetChipParams>): Promise<APIResponse<Listing<Chip>>> {
  const res = await api
    .get(
      `/chip/listChip?page=${params.page}&limit=${params.limit}&chi_name=${params.key}&chip_serial=${params.serial}`,
      { signal },
    )
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function getChipNotPage({
  signal,
  params,
}: ApiGETRequest<{ page: number; key?: string }>): Promise<
  APIResponse<GetChip>
> {
  const res = await api
    .get(`/chip/listChip?page=${params.page}&limit=5&chi_name=${params.key}`, {
      signal,
    })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getChipById({
  signal,
  params,
}: ApiGETRequest<{ id: string }>): Promise<APIResponse<CompleteChip>> {
  const res = await api
    .get(`/chip/byId/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getChipByCompany({
  params,
  signal,
}: ApiGETRequest<{ id: string }>): Promise<APIResponse<Chip[]>> {
  const res = await api
    .get(`/chip/byCompany/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}
