import dateFormat from 'dateformat';

/** Formata uma data para o formato yyyy-mm-dd */
export function formatDate(date?: string | Date | number): string | null {
  if (!date) {
    return null;
  }
  return dateFormat(date, 'yyyy-mm-dd');
}

/** Formata data e hora para o formato yyyy-mmm-dd HH:MM:ss */
export function formatDateTime(date: string | Date | number): string | null {
  if (!date) {
    return null;
  }
  return dateFormat(date, 'yyyy-mm-dd HH:MM:ss');
}

/** Formata uma data para o formato dd/mm/yyyy */
export function formatBrazillianDate(date: string | number | Date) {
  return dateFormat(date, 'dd/mm/yyyy');
}

/** Formata data e hora para o formato dd/mm/yyyy HH:MM */
export function formatBrazillianDateTime(date: string | number | Date) {
  return dateFormat(date, 'dd/mm/yyyy HH:MM');
}
