import styled from 'styled-components';
import colors from '../../styles/colors';

interface Props {
  marginTop?: string;
}

export const HeaderActions = styled.div<Props>`
  width: 100%;
  display: flex;
  margin-bottom: 0.3rem;
  position: relative;
  align-items: end;

  button {
    margin-top: ${props => props.marginTop};
    width: fit-content;
    display: flex;
    align-items: center;
    color: ${colors.blue_light};
    font-weight: bold;
    font-size: 16px;
    transition: all 0.2s ease-in;

    svg {
      margin-right: 0.5rem;
      transition: all 0.2s ease-in;
    }

    &:hover {
      color: ${colors.blue_light};

      svg {
        fill: ${colors.blue_light};
      }
    }
  }
`;
export const UserData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5rem;
`;

export const HeaderTitle = styled.span`
  width: fit-content;
  font-weight: 600;
  font-size: 1.5rem;
  padding-left: 0.25rem;
  color: ${colors.white};
`;

export const HeaderSubtitle = styled.span`
  width: fit-content;
  font-weight: 600;
  font-size: 0.94rem;
  padding-left: 0.25rem;
  color: ${colors.white};
`;
