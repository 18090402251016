import api, {
  ApiGETRequest,
  ApiPOSTRequest,
  APIResponse,
  Listing,
} from '../Api/api';
import { ResponseImport } from '../Equipment/types';
import {
  Component,
  Components,
  ReturnComponent,
  ReturnComponentByMachine,
  TransferComponent,
} from './types';

export async function getComponent({
  params: {
    page = 0,
    key = '',
    gru_id = '',
    status = '',
    comMake = '',
    agrCode = '',
  },
  signal,
}: ApiGETRequest<{
  page: number;
  key: string;
  gru_id: string;
  status: string;
  agrCode: string;
  comMake: string;
}>): Promise<APIResponse<ReturnComponent>> {
  const res = await api
    .get(
      `companyComponent/components?page=${page}&limit=5&com_description=${key}&gru_id=${gru_id}&status=${status}&com_make=${comMake}&com_agr_code=${agrCode}`,
      { signal },
    )
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getComponentByID({
  signal,
  params,
}: ApiGETRequest<{ id: string }>): Promise<APIResponse<Component>> {
  const res = await api
    .get(`companyComponent/byId/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getComponentByCompany({
  params,
  signal,
}: ApiGETRequest<{ id: string }>): Promise<APIResponse<Component[]>> {
  const res = await api
    .get(`companyComponent/byCompany/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getComponentByChip({
  signal,
}: ApiGETRequest): Promise<APIResponse<Listing<Component>>> {
  const res = await api
    .get('/componentChip/1?page=&limit=', { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getComponentQualitative({
  params,
  signal,
}: ApiGETRequest<{ id: number }>): Promise<APIResponse<Component[]>> {
  const res = await api
    .get(`/companyComponent/byChecklist/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function getComponentByMachine({
  params,
  signal,
}: ApiGETRequest<{ id: string }>): Promise<
  APIResponse<ReturnComponentByMachine[]>
> {
  const res = await api
    .get(`/companyComponent/listComponent/ByMachine/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function importExcelComponent({
  body,
}: ApiPOSTRequest<{ file: File; config?: unknown }>): Promise<
  APIResponse<ResponseImport[]>
> {
  const form = new FormData();
  form.append('file', body.file);

  const res = await api
    .post('companyComponent/component/import', form, body.config)
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function createComponent(
  dataParam: Components,
): Promise<APIResponse> {
  const res = await api
    .post('companyComponent/', dataParam)
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function updateComponent(
  dataParam: Components,
): Promise<APIResponse> {
  const res = await api
    .put('companyComponent/', dataParam)
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function deleteComponent(id: string): Promise<APIResponse> {
  const res = await api
    .delete(`companyComponent/${id}`)
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function transferComponent(
  dataParam: TransferComponent,
): Promise<APIResponse> {
  const res = await api
    .post('/companyComponent/changeCompany', dataParam)
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}

export async function updateComponentManual(body: {
  com_cmp_id: string;
  com_agr_code: string;
  com_manual: string;
}): Promise<APIResponse> {
  const res = await api
    .patch('/companyComponent/manual', body)
    .then(response => response)
    .catch(err => err.response);
  return res.data;
}
