import { yupResolver } from '@hookform/resolvers/yup';
import {
  Badge,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BsBell } from 'react-icons/bs';
import { FaAngleDown, FaPlus, FaPowerOff } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { IoMdMenu } from 'react-icons/io';
import { useAuth } from '../../contexts/authContext';
import {
  createPushNotification,
  getNotifications,
} from '../../services/Notification';
import { Notification } from '../../services/Notification/types';
import { errorToast, successToast } from '../../utils/functions';
import Button from '../Button';
import Input from '../Input';
import Modal from '../Modal';
import NotificationDetailsModal from './NotificationDetailsModal';
import {
  ButtonNotification,
  Container,
  Content,
  Data,
  LanguageContent,
  LanguageOptions,
  NotificationHeader,
  NotificationOptions,
  Notifications,
  ProfileContent,
  ProfileOptions,
} from './styles';

type HeaderProps = {
  setNavbarOpen: Dispatch<SetStateAction<boolean>>;
};

export default function Header({ setNavbarOpen }: HeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleLogout, user, changeLanguage, currentLanguage } = useAuth();

  const [clickedNotificationId, setClickedNotificationId] = useState(0);
  const [notificationList, setNotificationList] = useState<Notification[]>([]);
  const [unreadNotificationsCount, setUnreadNotificationCount] = useState(0);
  const [profileDropdownOpened, setProfileDropdownOpened] = useState(false);
  const [languageDropdownOpened, setLanguageDropdownOpened] = useState(false);
  const [notificationDropdownOpened, setNotificationDropdownOpened] =
    useState(false);
  const [addNotificationModalOpened, setAddNotificationModalOpened] =
    useState(false);
  const [notificationDetailsModalOpened, setNotificationDetailsModalOpened] =
    useState(false);

  const languageContainerRef = useRef<HTMLDivElement>();
  const profileContainerRef = useRef<HTMLDivElement>();
  const notificationContainerRef = useRef<HTMLDivElement>();

  const createNotificationSchema = yup.object().shape({
    body: yup.string().required('Insira uma descrição'),
    recrsuvei: yup.boolean(),
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<{
    body: string;
    recursive: boolean;
  }>({
    defaultValues: { recursive: false, body: '' },
    resolver: yupResolver<yup.AnyObjectSchema>(createNotificationSchema),
  });

  const getAllNotifications = useCallback(async (signal?: AbortSignal) => {
    const response = await getNotifications({ signal });

    if (response.error === 0) {
      setNotificationList(response.data.notifications);
      setUnreadNotificationCount(response.data.not_read);
    }
  }, []);

  const handleCreateNotificationSubmit = async (data: {
    body: string;
    recursive: boolean;
  }) => {
    const response = await createPushNotification({
      body: {
        body: data.body,
        recursive: data.recursive,
        cmp_id: user.cmpid,
      },
    });

    if (response.error === 0) {
      successToast(response.message);
      reset();
      setAddNotificationModalOpened(false);
      getAllNotifications();
    } else {
      errorToast(response.message);
    }
  };

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpened(prev => !prev);
  };
  const toggleProfileDropdown = () => {
    setProfileDropdownOpened(prev => !prev);
  };
  const toggleNotificationDropdown = () => {
    setNotificationDropdownOpened(prev => !prev);
  };
  const handleClickOutside = useCallback(event => {
    if (!languageContainerRef.current.contains(event.target)) {
      setLanguageDropdownOpened(false);
    }
    if (!profileContainerRef.current.contains(event.target)) {
      setProfileDropdownOpened(false);
    }
    if (!notificationContainerRef.current.contains(event.target)) {
      setNotificationDropdownOpened(false);
    }
  }, []);

  const clickToLogout = () => {
    handleLogout();
    setProfileDropdownOpened(false);
  };

  const handleNotificationDetailsClick = (notificationId: number) => {
    setClickedNotificationId(notificationId);
    setNotificationDetailsModalOpened(true);
  };
  const handleNotificationDetailsClose = () => {
    setNotificationDetailsModalOpened(false);
  };

  const handleAddNotificationClick = () => {
    setAddNotificationModalOpened(true);
  };
  const handleAddNotificationClose = () => {
    setAddNotificationModalOpened(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    setTimeout(() => getAllNotifications(controller.signal), 1000);

    return () => {
      controller.abort();
    };
  }, [getAllNotifications]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <Container>
        <div>
          <IconButton onClick={() => setNavbarOpen(true)}>
            <IoMdMenu color="white" size={20} />
          </IconButton>
        </div>
        <Content>
          <LanguageContent ref={languageContainerRef}>
            <Data>
              <Button
                variantText
                type="button"
                onClick={toggleLanguageDropdown}
                icon={<FaAngleDown size={14} />}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/${currentLanguage.flagPath}.png`}
                  width="25px"
                  alt={currentLanguage.name}
                  style={{ marginRight: 8 }}
                />
                {currentLanguage.name}
              </Button>
            </Data>
            <LanguageOptions open={languageDropdownOpened}>
              <ul>
                <Button
                  variantText
                  type="button"
                  onClick={() => changeLanguage(0)}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/brazilFlagIcon.png`}
                    alt="Bandeira do Brasil"
                  />
                  Português
                </Button>
                <Button
                  variantText
                  type="button"
                  onClick={() => changeLanguage(1)}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/usaFlagIcon.png`}
                    alt="Bandeira dos Estados Unidos"
                  />
                  English
                </Button>
                <Button
                  variantText
                  type="button"
                  onClick={() => changeLanguage(2)}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/spainFlagIcon.png`}
                    alt="Bandeira da Espanha"
                  />
                  Español
                </Button>
              </ul>
            </LanguageOptions>
          </LanguageContent>
          <ProfileContent ref={profileContainerRef}>
            <Data>
              <Button
                variantText
                type="button"
                onClick={toggleProfileDropdown}
                icon={<FaAngleDown size={14} />}
              >
                <p>{user?.name}</p>
              </Button>
            </Data>
            <ProfileOptions open={profileDropdownOpened}>
              <ul>
                <Button
                  variantText
                  type="button"
                  onClick={() => navigate('/profile')}
                >
                  {t('profile-my')}
                </Button>
                <Button
                  variantText
                  type="button"
                  onClick={() => navigate(`/company/edit/${user?.cmpid}`)}
                >
                  {t('profile-my-company')}
                </Button>
                <Button
                  variantText
                  type="button"
                  icon={<FaPowerOff size={14} color="#ed4c4c" />}
                  onClick={clickToLogout}
                >
                  {t('profile-logout-text')}
                </Button>
              </ul>
            </ProfileOptions>
          </ProfileContent>
          <Notifications ref={notificationContainerRef}>
            <Data>
              <button type="button" onClick={toggleNotificationDropdown}>
                <Badge color="warning" badgeContent={unreadNotificationsCount}>
                  <BsBell size={22} color="white" />
                </Badge>
              </button>
            </Data>
            <NotificationOptions open={notificationDropdownOpened}>
              <NotificationHeader>
                <span>Notificações</span>
                <IconButton size="small" onClick={handleAddNotificationClick}>
                  <FaPlus />
                </IconButton>
              </NotificationHeader>
              <ul>
                {notificationList.map(e => (
                  <ButtonNotification
                    view={e.usr_pus_read}
                    type="button"
                    key={e.pus_id}
                    onClick={() => handleNotificationDetailsClick(e.pus_id)}
                  >
                    <p>{e.pus_body}</p>
                  </ButtonNotification>
                ))}
              </ul>
            </NotificationOptions>
          </Notifications>
        </Content>
      </Container>

      {notificationDetailsModalOpened && (
        <NotificationDetailsModal
          notificationId={clickedNotificationId}
          onClose={handleNotificationDetailsClose}
        />
      )}

      {addNotificationModalOpened && (
        <Modal
          maxWidth="500px"
          onClick={handleAddNotificationClose}
          title="Criar Notificação"
        >
          <form onSubmit={handleSubmit(handleCreateNotificationSubmit)}>
            <Controller
              name="body"
              control={control}
              render={controllerState => (
                <Input
                  label="Descrição"
                  type="text"
                  errorMessage={errors?.body?.message}
                  value={controllerState.field.value}
                  onChange={e => setValue('body', e.target.value)}
                />
              )}
            />
            <Controller
              name="recursive"
              control={control}
              render={controllerState => (
                <Box width="100%" padding="0 0.75rem" marginBottom="1rem">
                  <FormControlLabel
                    label="Enviar para todas as empresas"
                    control={
                      <Checkbox
                        value={controllerState.field.value}
                        onChange={() => {
                          setValue('recursive', !controllerState.field.value);
                        }}
                      />
                    }
                  />
                </Box>
              )}
            />
            <Button type="submit">Enviar</Button>
          </form>
        </Modal>
      )}
    </>
  );
}
