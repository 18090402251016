/** Array de extensões de imagens */
export const imageFormats = [
  'png',
  'jpg',
  'jpeg',
  'avif',
  'tif',
  'tiff',
  'bmp',
  'gif',
];

/** Array de extensões de videos */
export const videoFormats = ['mp4', 'webm', 'ogg'];
