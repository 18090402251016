import styled from 'styled-components';

interface CountdownProps {
  width: string;
}

export const Container = styled.div<CountdownProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width};
  padding: 1.2rem 0.5rem 2.1rem;
`;
