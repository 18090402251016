import { CSSProperties } from 'react';
import ReactDropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import styled from 'styled-components';

interface DropzoneProps {
  disabled?: boolean;
  accept: {
    [index: string]: string[];
  };
  onDropAccepted(files: File[]): void;
  style?: CSSProperties;
}

export default function Dropzone({
  disabled = false,
  accept,
  onDropAccepted,
  style,
}: DropzoneProps) {
  const { t } = useTranslation();

  const renderImage = (isDragActive: boolean, isDragReject: boolean) => {
    if (!isDragActive) {
      return (
        <div
          style={{
            display: 'flex',
            color: '#999',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 30px 0',
          }}
        >
          {t('drag-files')}
          <AiOutlineCloudUpload />
        </div>
      );
    }
    if (isDragReject) {
      return (
        <div
          style={{
            display: 'flex',
            color: '#e57878',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 30px 0',
          }}
        >
          {t('drag-unsupported-file')}
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          color: '#78e5d5',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 30px 0',
        }}
      >
        {t('drag-drop-file')}
      </div>
    );
  };

  const getBorderColor = (isDragActive: boolean, isDragReject: boolean) => {
    if (isDragActive) {
      return '#78e5d5';
    }

    if (isDragReject) {
      return '#e57878';
    }

    return '#ddd';
  };

  return (
    <ReactDropzone
      disabled={disabled}
      accept={accept}
      onDropAccepted={onDropAccepted}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <div
          style={{
            border: '1px dashed #ddd',
            borderRadius: '4px',
            cursor: 'pointer',
            width: '46%',
            transition: 'height 0.2s ease',
            height: '36px',
            marginTop: '20px',
            marginLeft: '12px',
            borderColor: getBorderColor(isDragActive, isDragReject),
            ...style,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {renderImage(isDragActive, isDragReject)}
        </div>
      )}
    </ReactDropzone>
  );
}

export const ViewImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 16px;

  img {
    max-width: 550px;
    image-rendering: pixelated;
    border-radius: 8px;
    transition: transform 1s, filter 0.5s ease-out;

    :hover {
      transform: scale(1.1);
    }
  }
`;
