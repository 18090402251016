import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 0 13px;
  width: 50%;
  margin-top: 4px;

  fieldset {
    border: none;
  }

  > span {
    font-size: 0.75rem;
    margin-bottom: 2px;
    color: ${colors.dark_blue};
  }
  > div {
    input {
      padding: 7px;
      font-size: 14px;
      font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    }
    > div {
      background: ${colors.inputs.background};
    }
  }
`;
