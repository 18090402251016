import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import * as S from './styles';

interface ViewPassProps {
  viewPass: boolean;
  setViewPass: (boolean: boolean) => void;
  setType: (boolean: boolean) => void;
  types: boolean;
}
export default function ViewPassword({
  viewPass,
  setViewPass,
  setType,
  types,
}: ViewPassProps) {
  const handleViewPass = () => {
    setViewPass(!viewPass);
    setType(!types);
  };

  return (
    <S.Container>
      {!viewPass ? (
        <AiOutlineEye onClick={handleViewPass} size={20} />
      ) : (
        <AiOutlineEyeInvisible onClick={handleViewPass} size={20} />
      )}
    </S.Container>
  );
}
