import { Tooltip } from '@mui/material';
import React, {
  forwardRef,
  InputHTMLAttributes,
  LegacyRef,
  ReactNode,
} from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { numberMask, parseBRL } from '../../utils/functions';
import { Container, ErrorMessage } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type: string;
  label?: string;
  children?: ReactNode;
  width?: string;
  height?: string;
  info?: string;
  errorMessage?: any;
  containerStyle?: React.CSSProperties;
}

const Input = forwardRef(
  (
    {
      label,
      type,
      info,
      children,
      width = '100%',
      height,
      errorMessage,
      onChange,
      containerStyle,
      ...rest
    }: InputProps,
    ref: LegacyRef<HTMLInputElement>,
  ) => {
    return (
      <Container
        width={width}
        height={height}
        childrenProps={!!children}
        style={containerStyle}
      >
        <div>
          <label>
            <span style={{ display: 'flex', gap: '4px' }}>
              {label}
              {info && (
                <Tooltip placement="top" arrow title={info}>
                  <div>
                    <IoMdInformationCircleOutline size="12px" />
                  </div>
                </Tooltip>
              )}
            </span>
            <input
              type={type}
              ref={ref}
              {...rest}
              onChange={e => {
                if (type === 'currency') {
                  parseBRL(e, 2);
                } else if (type === 'numeric') {
                  numberMask(e);
                }
                onChange?.(e);
              }}
            />
            {children && <div>{children}</div>}
          </label>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
      </Container>
    );
  },
);

export default Input;
