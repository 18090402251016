/* eslint-disable import/no-extraneous-dependencies */
import { saveAs } from 'file-saver';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '../Button';
import { FooterTwo } from '../Modal/styles';
import { Wrapper, WrapperError, WrapperLoad } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFDisplayProps {
  url: string;
  toggleModal: (isShowing: boolean) => void;
}

export default function PDFDisplay({ url, toggleModal }: PDFDisplayProps) {
  const { t } = useTranslation();

  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const file = useMemo(() => ({ url }), [url]);

  const onDocumentLoadSuccess = useCallback(async successEvent => {
    const data = await successEvent?.getData();
    setFileBlob(new Blob([data], { type: 'application/pdf' }));
  }, []);

  const handleClick = useCallback(() => {
    saveAs(URL.createObjectURL(fileBlob), 'relatorio.pdf');
  }, [fileBlob]);

  const handleClickCancel = useCallback(() => {
    toggleModal(false);
  }, [toggleModal]);

  return (
    <Wrapper>
      <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <Document
          file={file}
          error={
            <WrapperError>
              <BiError size={50} color="red" />
              <p>Houve um erro inesperado</p>
              <Button type="button" width="150px" onClick={handleClickCancel}>
                {t('generic-button-close')}
              </Button>
            </WrapperError>
          }
          loading={
            <WrapperLoad>
              <div>
                <section />
                <div>
                  <span />
                  <span />
                  <span />
                </div>
              </div>
              <p style={{ marginTop: '4rem' }}>
                {t('generate-report-loading')}
              </p>
            </WrapperLoad>
          }
          onLoadSuccess={successEvent => onDocumentLoadSuccess(successEvent)}
        >
          <Page pageNumber={1} />
        </Document>
      </div>
      {fileBlob && (
        <FooterTwo style={{ justifyContent: 'flex-end', marginTop: '1rem' }}>
          <Button type="button" onClick={handleClick} width="150px">
            Download
            <AiOutlineCloudDownload size={20} style={{ marginLeft: '8px' }} />
          </Button>
        </FooterTwo>
      )}
    </Wrapper>
  );
}
