import styled from 'styled-components';

export const WrapperPagination = styled.div`
  display: flex;
  grid-gap: 10px;
  justify-content: center;
  margin-top: 20px;

  input {
    padding-left: 8px;
    border-radius: 5px;
    border: 1px solid gray;
  }
  select {
    border-radius: 5px;
    border: 1px solid gray;
  }
`;
