import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  hoverAnimation?: boolean;
  clickable?: boolean;
}

export const Card = styled.div<CardProps>`
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow: hidden;
  padding: 16px;
  padding-bottom: 24px;

  ${props =>
    props.hoverAnimation &&
    css`
      transition: 0.2s ease-in-out all;

      :hover {
        transform: translateY(-4px);
        cursor: ${props.clickable ? 'pointer' : ''};
        box-shadow: 0px 4px 8px #37474f;
      }
    `}
`;
