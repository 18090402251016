import styled from 'styled-components';
import colors from '../../styles/colors';

interface PreviewProps {
  src?: string;
}

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  margin-right: 12px;
  width: 100%;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;

    & + li {
      margin-top: 15px;
    }

    div {
      display: flex;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;

  > div {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  span {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #999;
    margin-top: 5px;
    justify-content: space-between;
    padding-left: 10px;

    button {
      border: 0;
      color: #e57878;
      margin-left: 5px;
      cursor: pointer;
      transition: 0.2s linear;
      :hover {
        color: ${colors.red_strong};
      }
    }
  }
`;

export const Preview = styled.div<PreviewProps>`
  width: 38px;
  height: 38px;
  border-radius: 5px;
  background-image: url(${p => p.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  transition: 0.19s linear;
  border: 1px solid #ddd;

  svg {
    width: 25px;
    height: 25px;
    fill: black;
    color: black;
    margin: auto;
    transition: 0.19s linear;
  }

  :hover {
    svg {
      width: 35px;
      height: 35px;
    }
    cursor: pointer;
    width: 48px;
    height: 48px;
    box-shadow: 0 0 8px #666;
  }
`;
