export default {
  white: '#fff',
  blue_light: '#00bbf1',
  dark_blue: '#2e2c73',
  dark: '#222222',
  gray: '#e6e7e8',
  red_light: '#FF5F5F',
  red_strong: '#F00000',
  green: '#119e37',
  inputs: {
    background: 'rgba(210, 210, 210, 0.4)',
    errorMessage: 'tomato',
  },
  linear: 'linear-gradient(0deg, #00BBF1 0%, rgba(46, 44, 115, 0.979167) 100%)',
};
