import api, { ApiPOSTRequest, APIResponse } from './Api/api';

interface SignInRequestData {
  username: string;
  password: string;
  captcha: string | null | undefined;
  action?: string;
}

export interface PermissionsLogged {
  per_id?: string;
  per_name: string;
}

export interface UserCredential {
  name: string;
  username: string;
  company: string;
  document?: string;
  accessid: number;
  email: string;
  cmpid: string;
  userid: string;
  roldescription: string;
  permissions: PermissionsLogged[];
  rol_id: string;
  optin: number;
}

export interface SignInResponse {
  credential: UserCredential;
  token: string;
  refresh_token: string;
}

export async function signInRequest({
  body,
}: ApiPOSTRequest<SignInRequestData>): Promise<APIResponse<SignInResponse>> {
  const res = await api
    .post('/sessions', body)
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}
