import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';
import Button from '../Button';
import Modal from '../Modal';
import { FooterTwo } from '../Modal/styles';
import {
  AdditionalInfoContainer,
  ContentFirst,
  ContentTwo,
  Space,
} from './styles';

interface DeleteModalProps {
  title?: string;
  deleteButtonLabel?: string;
  aditionalInfo?: string;
  action: () => void;
  onClose: () => void;
}

export default function DeleteModal({
  action,
  title = 'modal-delete-title',
  deleteButtonLabel = 'modal-delete-button',
  aditionalInfo,
  onClose,
}: DeleteModalProps) {
  const { t } = useTranslation();

  return (
    <Modal maxWidth="485px" title={t(title)} onClick={onClose}>
      {aditionalInfo ? (
        <AdditionalInfoContainer>{aditionalInfo}</AdditionalInfoContainer>
      ) : (
        <Space />
      )}
      <FooterTwo>
        <ContentFirst>
          <Button type="button" onClick={onClose} width="150px">
            {t('company-edit-cancel')}
          </Button>
        </ContentFirst>

        <ContentTwo>
          <Button
            className="delete"
            type="button"
            onClick={action}
            width="150px"
            icon={
              deleteButtonLabel === 'modal-delete-button' && (
                <FaTrashAlt size={14} />
              )
            }
          >
            {t(deleteButtonLabel)}
          </Button>
        </ContentTwo>
      </FooterTwo>
    </Modal>
  );
}
