import { forwardRef, InputHTMLAttributes, LegacyRef } from 'react';

import { Container } from './styles';

interface InputRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  text: string;
}

const InputRadio = forwardRef(
  ({ text, ...rest }: InputRadioProps, ref: LegacyRef<HTMLInputElement>) => {
    return (
      <Container>
        {text}
        <input ref={ref} {...rest} />
        <span />
      </Container>
    );
  },
);

export default InputRadio;
