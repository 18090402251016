import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';
import { forwardRef, Ref } from 'react';
import { ErrorMessage } from '../Input/styles';
import { Container } from './styles';

interface DateTimePickerProps {
  title: string;
  value: any;
  disabled: boolean;
  errorMessage: string | any;
  onChange: (value: Date) => void;
}

const DateTimePicker = forwardRef(
  (
    { onChange, value, title, disabled, errorMessage }: DateTimePickerProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Container>
          <span>{title}</span>
          <MUIDateTimePicker
            value={value}
            disabled={disabled}
            renderInput={props => <TextField {...props} />}
            onChange={onChange}
            ampm={false}
            inputFormat="dd/MM/yyyy HH:mm"
            ref={ref}
          />
          <ErrorMessage>{errorMessage && errorMessage}</ErrorMessage>
        </Container>
      </LocalizationProvider>
    );
  },
);

export default DateTimePicker;
