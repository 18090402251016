import styled, { css } from 'styled-components';

type ContainerProps = {
  hasPadding?: boolean;
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  max-width: 100vw;
  background-attachment: fixed;
`;

export const ContainerChildren = styled.div<ContainerProps>`
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 1440px) {
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;
  }

  ${p =>
    p.hasPadding
      ? css`
          padding: 3rem 2.5rem 2rem 8rem;

          @media screen and (max-width: 720px) {
            padding: 3.15rem 2.5rem 2rem 2.5rem;
          }
        `
      : css`
          padding: 0;
        `}
`;
