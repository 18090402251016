import axios from 'axios';
import Cookies from 'js-cookie';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import api from '../services/Api/api';

import {
  PermissionsLogged,
  signInRequest,
  UserCredential,
} from '../services/auth';
import { errorToast } from '../utils/functions';

export interface User {
  name: string;
  username: string;
  company: string;
  accessid: number;
  email: string;
  cmpid: string;
  userid: string;
  roldescription: string;
  permissions: PermissionsLogged[];
  rol_id: string;
}

interface SignInCredentials {
  username: string;
  password: string;
  captcha: string | null | undefined;
  action?: string;
}

interface LoginResponse {
  credential?: UserCredential;
  optin: boolean;
}

interface AuthContextValue {
  user: Partial<User> | null;
  currentLanguage: Language;
  isLoadingServer: boolean;
  handleLogin(data: SignInCredentials): Promise<LoginResponse>;
  handleLogout(): void;
  updateLoggedUser: (value: any) => void;
  changeLanguage: (value: 0 | 1 | 2) => void;
  validationOptin: (value: UserCredential) => boolean;
  getUser: () => Partial<User>;
  hasAccess: (code: string) => boolean;
}

const languages: Language[] = [
  {
    name: 'Português',
    short: 'pt',
    iso: 'pt-BR',
    flagPath: 'brazilFlagIcon',
  },
  {
    name: 'English',
    short: 'en',
    iso: 'en-US',
    flagPath: 'usaFlagIcon',
  },
  {
    name: 'Español',
    short: 'es',
    iso: 'es',
    flagPath: 'spainFlagIcon',
  },
];

type Language = {
  name: string;
  short: string;
  iso: string;
  flagPath: string;
};

const AuthContext = createContext<AuthContextValue>({
  user: null,
  currentLanguage: null,
  isLoadingServer: false,

  handleLogin: () => null,
  handleLogout: () => null,
  updateLoggedUser: () => null,
  changeLanguage: () => null,
  validationOptin: () => false,
  getUser: () => null,
  hasAccess: () => false,
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { i18n } = useTranslation();

  const [user, setUser] = useState<User | null>(null);
  const [isLoadingServer, setIsLoadingServer] = useState(false);

  const [currentLanguage, setCurrentLanguage] = useState<Language>(() => {
    const storedLanguage = Number(Cookies.get('language')) || 0;
    i18n.changeLanguage(languages[storedLanguage].short);
    return languages[storedLanguage];
  });

  const [permissions, setPermissions] = useState<string[]>(() => {
    const access = localStorage.getItem('@Data');
    if (!access) return [];
    return JSON.parse(access);
  });

  const hasAccess = useCallback(
    (code: string) => permissions.includes(code),
    [permissions],
  );

  const handleLogout = useCallback(() => {
    localStorage.clear();
    api.defaults.headers.common.authorization = '';
    setUser(null);
    setPermissions([]);
  }, []);

  const changeLanguage = useCallback(
    (lang: 0 | 1 | 2) => {
      Cookies.set('language', String(lang), {
        expires: 999999999,
      });

      const l = languages[lang];

      api.defaults.headers.common.lang = l.iso;
      i18n.changeLanguage(l.short);
      setCurrentLanguage(l);
    },
    [i18n],
  );

  const validationOptin = useCallback((credential: UserCredential): boolean => {
    const perms = credential.permissions.map(e => e.per_name);

    localStorage.setItem('@Data', JSON.stringify(perms));
    setPermissions(perms);
    setUser(credential);
    setIsLoadingServer(false);

    return true;
  }, []);

  const handleLogin = useCallback(
    async (data: SignInCredentials): Promise<LoginResponse> => {
      setIsLoadingServer(true);
      const response = await signInRequest({ body: data });
      setIsLoadingServer(false);

      if (!response) {
        errorToast('Houve um erro inesperado.');
        return { optin: false };
      }

      if (response.error !== 0) {
        errorToast(response.message);
        return { optin: false };
      }

      if (
        response.data?.token &&
        response.data?.credential &&
        response.data?.refresh_token
      ) {
        api.defaults.headers.common.authorization = `Bearer ${response.data?.token}`;

        localStorage.setItem('@App:token', response.data.token);
        localStorage.setItem('@App:refresh_token', response.data.refresh_token);
        localStorage.setItem(
          '@App:user',
          JSON.stringify(response.data.credential),
        );
        Cookies.remove('@App:cart');

        setIsLoadingServer(false);
        return {
          credential: response.data.credential,
          optin:
            response.data.credential.optin === 1
              ? validationOptin(response.data.credential)
              : false,
        };
      }

      return { optin: false };
    },
    [validationOptin],
  );

  const refreshToken = useCallback(async (): Promise<{
    token: string;
    status: number;
  }> => {
    const refresh = localStorage.getItem('@App:refresh_token');

    const response = await api.post('/refresh-token', {
      token: refresh,
    });

    if (response.data.error > 0) {
      handleLogout();
      window.location.reload();
      return { status: response.status, token: '' };
    }

    const { token, refresh_token } = response.data.data;

    api.defaults.headers.common.authorization = `Bearer ${token}`;
    localStorage.setItem('@App:token', token);
    localStorage.setItem('@App:refresh_token', refresh_token);

    return { status: response.status, token: response.data.data.token };
  }, [handleLogout]);

  useEffect(() => {
    api.interceptors.response.use(
      response => {
        return response;
      },
      async ({ response, config }) => {
        const originalReq = config;

        if (response.status === 401) {
          if (response.data.error === 10) {
            errorToast('Voce não possui permissão para realizar essa ação');
            // eslint-disable-next-line no-underscore-dangle
            originalReq._retry = false;
            return axios(originalReq);
          }

          // eslint-disable-next-line no-underscore-dangle
          originalReq._retry = true;
          const responseRefreshToken = await refreshToken();

          if (
            responseRefreshToken?.status === 200 &&
            responseRefreshToken?.token
          ) {
            originalReq.headers.authorization = `Bearer ${responseRefreshToken.token}`;
            api.defaults.headers.common.authorization = `Bearer ${responseRefreshToken.token}`;
            return axios(originalReq);
          }
        }

        if (response.status === 403) {
          handleLogout();
          window.location.href = '/';
        }
        return response;
      },
    );
  }, [handleLogout, refreshToken]);

  const updateLoggedUser = useCallback((data: UserCredential) => {
    const getUser = JSON.parse(localStorage.getItem('@App:user') || '{}');
    const newData = Object.assign(getUser, data);
    setUser(newData);
    localStorage.setItem('@App:user', JSON.stringify(newData));
    return newData;
  }, []);

  const getUser = useCallback(() => {
    if (user) return user;

    const localUser = JSON.parse(localStorage.getItem('@App:user') || '{}');
    return Object.keys(localUser).length === 0 ? null : localUser;
  }, [user]);

  useEffect(() => {
    const storagedToken = localStorage.getItem('@App:token');
    const storagedRefreshToken = localStorage.getItem('@App:refresh_token');
    const storagedUser = localStorage.getItem('@App:user');
    const access = localStorage.getItem('@Data');

    if (!storagedToken || !storagedRefreshToken || !storagedUser || !access) {
      // handleLogout();
      return;
    }

    setUser(JSON.parse(storagedUser));

    api.defaults.headers.common.authorization = `Bearer ${storagedToken}`;
  }, [handleLogout]);

  useEffect(() => {
    let language = Cookies.get('language');

    if (language) {
      language = language.match(/^\d$/) !== null ? language : '0';
    }

    api.defaults.headers.common.lang = languages[Number(language)]?.iso;

    Cookies.set('language', String(language), {
      expires: 999999999,
    });
  }, []);

  const value = useMemo(
    () => ({
      user,
      currentLanguage,
      isLoadingServer,
      validationOptin,
      changeLanguage,
      handleLogin,
      handleLogout,
      updateLoggedUser,
      getUser,
      hasAccess,
    }),
    [
      user,
      currentLanguage,
      isLoadingServer,
      validationOptin,
      changeLanguage,
      handleLogin,
      handleLogout,
      updateLoggedUser,
      getUser,
      hasAccess,
    ],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
