import { forwardRef, LegacyRef, ReactNode, SelectHTMLAttributes } from 'react';

import { Tooltip } from '@mui/material';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { Container, ErrorMessage } from './styles';

interface DropdownProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  children?: ReactNode;
  width?: string;
  errorMessage?: string | any;
  info?: string;
}

const Dropdown = forwardRef(
  (
    {
      label,
      children,
      width = '100%',
      errorMessage,
      name,
      info,
      ...rest
    }: DropdownProps,
    ref: LegacyRef<HTMLSelectElement>,
  ) => {
    return (
      <Container width={width}>
        <div>
          <label>
            <span style={{ display: 'flex', gap: '4px' }}>
              {label}
              {info && (
                <Tooltip placement="top" arrow title={info}>
                  <div>
                    <IoMdInformationCircleOutline size="12px" />
                  </div>
                </Tooltip>
              )}
            </span>
            <select ref={ref} name={name} {...rest}>
              {children}
            </select>
          </label>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
      </Container>
    );
  },
);

export default Dropdown;
