import { ChangeEvent } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { FiUpload } from 'react-icons/fi';
import './styles.css';

type AttachFileProps = {
  title: string;
  filename: string;
  accept?: string;
  handleFile?(e: ChangeEvent<HTMLInputElement>): void;
} & (
  | {
      hasPreview?: true;
      file: string;
    }
  | {
      hasPreview?: false;
      file?: string | null;
    }
) &
  (
    | {
        register: UseFormRegister<FieldValues>;
        name: string;
      }
    | {
        register?: null | undefined;
        name?: string;
      }
  );

export default function AttachFile({
  title,
  file,
  filename,
  accept,
  handleFile,
  hasPreview,
  register,
  name,
}: AttachFileProps) {
  return (
    <div className="attach-file_container">
      <span className="attach-file_title">{title}</span>

      {hasPreview && (
        <>
          <img className="attach-file_image-preview" src={file} alt="" />
          <div className="attach-file_filename">{filename && filename}</div>
        </>
      )}

      <div className="attach-file_input-wrapper">
        <div className="attach-file_icon">
          <FiUpload size={30} />
        </div>
        {register ? (
          <input
            className="attach-file_input"
            type="file"
            accept={accept}
            {...register(name)}
          />
        ) : (
          <input
            className="attach-file_input"
            type="file"
            accept={accept}
            onChange={handleFile}
          />
        )}
      </div>

      {!hasPreview && (
        <div className="attach-file_filename">{filename && filename}</div>
      )}
    </div>
  );
}
