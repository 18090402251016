import styled, { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

export default createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      outline: 0;
      font-family: "Montserrat", Arial, Helvetica, sans-serif !important;
    }
    body {
      background: #f8f9fa;
      height: 100vh;
      color: #222222;
    }
    body ::selection{
      background: #311b92;
      color: white;
    }
    button {
      cursor: pointer;
      background: transparent;
      border: none;
    }
    @media(max-width: 1080px) {
      html {
          font-size: 93.75%;
      }
    }
    @media(max-width: 720px) {
      html {
          font-size: 87.5%;
      }
    }
`;

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast--success {
    background: #cff2dc;
    color: #0c6f32;

    .Toastify__close-button > svg {
      fill: #0c6f32;
    }

    .Toastify__progress-bar {
      background: #0c6f32;
    }
  }

  .Toastify__toast--error {
    background: #fff0f3;
    color: #ff0a3b;

    .Toastify__close-button > svg {
      fill: #ff0a3b;
    }

    .Toastify__progress-bar {
      background: #ff0a3b;
    }
  }
`;
