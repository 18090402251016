import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsChevronLeft,
  BsChevronRight,
} from 'react-icons/bs';
import { WrapperPagination } from './styles';

interface OnPage {
  n: number;
  add: boolean;
}

interface PaginationProps {
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  previousPage: () => void;
  nextPage: () => void;
  onPage?({ add, n }: OnPage): void;
  pageCount: number;
  pageOptions: number[];
  currentPage: number;
  setPage?: (value: number) => void;
}

const PaginationTableServerSide = ({
  gotoPage,
  previousPage,
  nextPage,
  pageCount,
  pageOptions,
  currentPage,
  onPage,
  setPage,
}: PaginationProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <WrapperPagination>
      <button
        type="button"
        onClick={() => setPage(0)}
        disabled={currentPage === 0}
      >
        <BsChevronDoubleLeft />
      </button>
      <button
        type="button"
        onClick={() => {
          previousPage();
          onPage({ n: currentPage, add: false });
        }}
        disabled={currentPage === 0}
      >
        <BsChevronLeft />
      </button>
      <button
        type="button"
        onClick={() => {
          nextPage();
          onPage({ n: currentPage, add: true });
        }}
        disabled={currentPage + 1 === pageCount}
      >
        <BsChevronRight />
      </button>{' '}
      <button
        type="button"
        onClick={() => setPage(pageCount - 1)}
        disabled={currentPage + 1 === pageCount}
      >
        <BsChevronDoubleRight />
      </button>{' '}
      <span>
        {t('table-page-page')}{' '}
        <strong>
          {currentPage + 1} {t('table-page-of')} {pageOptions.length}
        </strong>{' '}
      </span>
      <span>
        | {t('table-page-go-to-page')}:{' '}
        <input
          type="number"
          defaultValue={currentPage + 1}
          max={pageCount}
          onKeyUp={e => {
            if (e.key !== 'Enter') return;
            const page = e.currentTarget.value
              ? Number(e.currentTarget.value) - 1
              : 0;
            if (page < pageCount && page >= 0) {
              setPage(page);
            }
            gotoPage(page);
          }}
          style={{ width: '100px' }}
        />
      </span>
    </WrapperPagination>
  );
};

export default PaginationTableServerSide;
