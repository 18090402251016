import axios from 'axios';

const getAPIClient = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Platform: 'web',
    },
  });

  api.defaults.validateStatus = status => {
    return status !== 401 && status !== 403;
  };
  return api;
};

export default getAPIClient;
