import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

interface Sidebar {
  active: boolean;
}

interface SidebarItemProps {
  active: boolean;
}

export const Container = styled.nav<Sidebar>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: ${p => (p.active ? '240px' : '70px')};
  background: #fff;
  transition: all 0.5s ease;
  box-shadow: 0px 0px 2px gray;
  z-index: 3;

  svg {
    transition: all 0.4s ease;
  }

  ul {
    margin-top: 20px;

    padding-bottom: 120px;

    > li {
      height: 40px;
      position: relative;
      width: 100%;
      list-style: none;
      margin: 0 5px;
      line-height: 40px;
      cursor: pointer;

      :hover {
        > span {
          transition: all 0.4s ease;
          top: 50%;
          opacity: 1;
        }
      }

      > div {
        color: #212121;
        display: flex;
        align-items: center;
        text-decoration: none;
        transition: all 0.4s ease;
        height: 40px;
        border-radius: 12px;
        white-space: nowrap;
        width: 100%;

        :hover {
          color: ${colors.blue_light};
          svg {
            color: ${colors.blue_light};
          }
        }

        > span {
          display: ${p => !p.active && 'none'};
          transition: ${p => p.active && 'display 4s ease-in'};

          ${p =>
            p.active
              ? css`
                  visibility: visible;
                  opacity: 1;
                  transition: visibility 0s, opacity 0.4s ease-in;
                `
              : css`
                  visibility: hidden;
                  opacity: 0;
                  transition: visibility 0s, opacity 0.4s ease-in;
                `}
        }
      }
    }
  }

  @media screen and (max-width: 720px) {
    transform: translateX(${p => (p.active ? '0%' : '-100%')});
  }
`;

export const SidebarItem = styled.li<SidebarItemProps>`
  width: 100%;

  > div {
    background-color: ${props => (props.active ? '#e8e8e8' : '')};
  }

  > div > div {
    background-color: ${props => (props.active ? '#e8e8e8' : '')};
  }

  :hover {
    > div > div,
    > div {
      background-color: #f4f4f4;
      background-color: ${props => (props.active ? '#d5d5d5' : '#f4f4f4')};
    }
  }
`;

export const ContainerLogo = styled.div`
  padding: 6px 10px;
  padding-right: 20px;
`;

export const ContainerUl = styled.div`
  padding: 6px 10px;
  padding-right: 20px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #4444;
    border-radius: 100px;
  }
`;

export const Icon = styled.div`
  height: 40px;
  color: #111;
  min-width: 40px;
  border-radius: 12px;
  line-height: 50px;
  transition: all 0.4s ease;
  text-align: center;

  > svg {
    margin-bottom: 3px;
  }
`;

export const LogoContent = styled.div<Sidebar>`
  color: #111;
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  align-items: center;
  font-size: 20px;
  transition: all 0.4s ease;
  cursor: pointer;

  :hover {
    color: ${colors.blue_light};
  }

  ${p =>
    !p.active &&
    css`
      :hover {
        color: ${colors.blue_light};
        background-color: #bcd8e0;
      }
      border-radius: 12px;
      margin-left: 5px;
    `}

  > img {
    pointer-events: none;
    opacity: ${p => !p.active && 0};
    transition: ${p => p.active && 'all 1s ease'};
  }
  > svg {
    transition: 0s;
    ${p =>
      !p.active &&
      css`
        z-index: 2;
        position: absolute;
        left: 35%;
      `}
  }
`;

export const ProfileContent = styled.div<Sidebar>`
  position: absolute;
  color: #111;
  bottom: 0;
  left: 0;
  width: 100%;

  > button {
    position: absolute;
    left: ${p => (p.active ? '88%' : '50%')};
    text-align: center;
    bottom: 5px;
    top: 8px;
    transform: translateX(-50%);
    min-width: 50px;
    font-size: 20px;
    line-height: 50px;
    color: #d32f2f;
    padding: 2px;
    border-radius: 12px;
    transition: all 0.4s ease;
    cursor: pointer;

    > button {
      color: #111;
      font-size: 16px;
    }

    :hover {
      background: #ff8a80;
      color: #d50000;
      transition: all 0.4s ease;

      > span {
        transition: all 0.4s ease;
        top: 50%;
        opacity: 1;
      }
    }
  }

  > div {
    position: relative;
    padding: 14px 6px 10px 7px;
    height: 60px;
    background: #e8e8e8;
    display: flex;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      white-space: nowrap;
      transition: all 0.4s ease;

      ${p =>
        p.active
          ? css`
              opacity: 1;
              pointer-events: auto;
            `
          : css`
              opacity: 0;
              pointer-events: none;
            `}

      > span {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        transition: background 0.4s ease;

        :hover {
          color: ${colors.blue_light};
          background-color: #bcd8e0;
        }
      }

      > div {
        margin-left: 10px;

        > div {
          font-size: 16px;
          font-weight: 400px;
        }
      }
    }
  }
`;

export const HomeText = styled.div<Sidebar>`
  position: absolute;
  height: 100%;

  ${p =>
    p.active
      ? css`
          width: calc(100% - 240px);
          left: 240px;
        `
      : css`
          width: calc(100% - 80px);
          left: 80px;
        `}

  > div {
    font-size: 25px;
    font-weight: 500px;
    color: #11101d;
    margin: 12px;
  }
`;

export const Tool = styled.span<Sidebar>`
  position: absolute;
  height: 35px;
  left: 180px;
  top: 20%;
  line-height: 35px;
  width: 160px;
  border-radius: 6px;
  transform: translate(-73%, -50%);
  text-align: center;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: ${p => (p.active ? 'none' : 'block')};
`;
