import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce } from 'react-table';
import { FilterContainer } from './styles';

function GlobalFilter({ globalFilter, setGlobalFilter }: any): ReactElement {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(v => {
    setGlobalFilter(v || undefined);
  }, 200);
  const { t } = useTranslation();

  return (
    <FilterContainer>
      <input
        value={value || ''}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={t('table-page-search')}
      />
    </FilterContainer>
  );
}

export default GlobalFilter;
