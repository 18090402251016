import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65vh;
`;

export const DataView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  position: absolute;
  font-size: 14px;
  padding: 20px;

  b {
    margin-bottom: 4px;
  }

  span {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
`;
