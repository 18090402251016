import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  colors,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { getEquipmentTimeStopped } from '../../services/Equipment';
import { ReturnMachineTimeStopped } from '../../services/Equipment/types';
import { Content, DataView } from './styles';

interface MachineStoppedTimeDonutChartProps {
  id: string;
}

export default function MachineStoppedTimeDonutChart({
  id,
}: MachineStoppedTimeDonutChartProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [machineTimeStopped, setMachineTimeStopped] =
    useState<ReturnMachineTimeStopped>({
      totalHoras: 0,
      funcional: 0,
      funcionalPercent: 0,
      parada: 0,
      paradaPercent: 0,
    });

  useEffect(() => {
    const getStoppedTime = async (equipmentId: string, signal: AbortSignal) => {
      const response = await getEquipmentTimeStopped({
        params: { id: equipmentId },
        signal,
      });

      if (response.error === 0) {
        setMachineTimeStopped(response.data);
      }

      setIsLoading(false);
    };

    const controller = new AbortController();

    getStoppedTime(id, controller.signal);
    return () => {
      controller.abort();
    };
  }, [id]);

  const data = {
    labels: [t('graphic-machine-functional'), t('graphic-machine-stopped')],
    datasets: [
      {
        label: '',
        data: [
          machineTimeStopped.funcionalPercent,
          machineTimeStopped.paradaPercent,
        ],
        backgroundColor: [colors.teal.A700, colors.red.A400],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card sx={{ height: '100%', width: '100%' }}>
      <CardHeader
        disableTypography
        sx={{
          fontSize: 16,
        }}
        title={t('Gráfico das horas totais da máquina')}
      />
      <Divider />

      {!isLoading ? (
        <>
          <DataView>
            <span>
              <b>Horas totais:</b>
              {machineTimeStopped.totalHoras}
            </span>
            <span>
              <b>{t('graphic-machine-stopped')}:</b>
              {machineTimeStopped.parada}
            </span>
            <span>
              <b>{t('graphic-machine-functional')}:</b>
              {machineTimeStopped.totalHoras}
            </span>
          </DataView>
          <CardContent>
            <Box
              sx={{
                height: 300,
                position: 'relative',
              }}
            >
              <Doughnut data={data} options={options} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pt: 2,
              }}
            >
              <Box
                sx={{
                  p: 1,
                  textAlign: 'center',
                  marginTop: 'auto',
                  marginRight: '10px',
                }}
              >
                <Typography color="textPrimary" variant="body1">
                  {t('graphic-machine-functional')}
                </Typography>
                <Typography style={{ color: colors.teal.A700 }} variant="h5">
                  {machineTimeStopped.funcionalPercent}%
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 1,
                  textAlign: 'center',
                  marginTop: 'auto',
                  marginLeft: '10px',
                }}
              >
                <Typography color="textPrimary" variant="body1">
                  {t('graphic-machine-stopped')}
                </Typography>
                <Typography style={{ color: colors.red.A400 }} variant="h5">
                  {machineTimeStopped.paradaPercent}%
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </>
      ) : (
        <Content>
          <CircularProgress color="info" size={70} />
        </Content>
      )}
    </Card>
  );
}
