import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { useAuth } from '../../contexts/authContext';

import { Container, Option } from './styles';

interface TabContentProps {
  id: number;
  name: string;
  node?: ReactNode;
  permissions?: string;
}

interface TabProps {
  data: TabContentProps[];
  onClick: (args0: any) => void;
  actualTab: number;
}

export default function Tabs({ data, actualTab, onClick }: TabProps) {
  const { t } = useTranslation();
  const { hasAccess } = useAuth();

  return (
    <Container>
      {data.map(({ name, id, node, permissions }) => {
        const perm = permissions ? hasAccess(permissions) : true;

        return (
          perm && (
            <Option
              key={id}
              active={actualTab === id}
              onClick={() => onClick(id)}
            >
              {node || <p style={{ padding: '0.5rem' }}>{t(name)}</p>}
            </Option>
          )
        );
      })}
    </Container>
  );
}
