import {
  forwardRef,
  LegacyRef,
  ReactNode,
  TextareaHTMLAttributes,
} from 'react';
import { Container, ErrorMessage } from './styles';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  width?: string;
  errorMessage?: ReactNode;
}

const InputArea = forwardRef(
  (
    { label, width = '100%', errorMessage, ...rest }: TextAreaProps,
    ref: LegacyRef<HTMLTextAreaElement>,
  ) => {
    return (
      <Container width={width}>
        <div>
          <label>
            <span>{label}</span>
            <textarea ref={ref} {...rest} />
          </label>
          <ErrorMessage>{errorMessage && errorMessage}</ErrorMessage>
        </div>
      </Container>
    );
  },
);

export default InputArea;
