import styled from 'styled-components';
import colors from '../../styles/colors';

interface ContainerProps {
  width: string;
}

export const Container = styled.div<ContainerProps>`
  width: ${p => p.width};
  padding: 0.75rem 0.75rem;

  > div {
    width: 100%;

    > label {
      width: 100%;
      display: flex;
      flex-direction: column;
      font-size: 0.75rem;
      gap: 4px;
      line-height: 0.75rem;
      color: ${colors.dark_blue};

      > textarea {
        width: 100%;
        border: 0;
        font-size: 0.87rem;
        line-height: 1.25rem;
        color: #0a0a0a;
        padding: 0.5rem 0.5rem;
        background: ${colors.inputs.background};
        border-radius: 4px;

        :read-only {
          color: #777777;
          cursor: not-allowed;
        }

        ::placeholder {
          color: #9e9e9e;
        }
      }
    }
  }
`;

export const ErrorMessage = styled.small`
  font-size: 10px;
  min-height: 13px;
  color: ${colors.inputs.errorMessage};
`;
