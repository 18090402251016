import { useCallback, useState } from 'react';

export default function useConvert() {
  const [imageConvert, setImage] = useState('');
  const [arrayImage, setArrayImage] = useState([] as string[]);

  const handleSetImage = useCallback(
    (imageParam: string) => {
      setImage(imageParam);
      if (!arrayImage[4]) {
        setArrayImage(oldValue => [...oldValue, imageParam]);
      }
    },
    [arrayImage],
  );

  const ImageToBase64 = useCallback(
    (file: File, setImageString?: (value: string) => void): void => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (): void => {
        handleSetImage(reader.result as string);
        setImageString?.(reader.result as string);
      };
      reader.onerror = () => {
        return false;
      };
    },
    [handleSetImage],
  );

  return {
    handleSetImage,
    ImageToBase64,
    imageConvert,
    arrayImage,
    setArrayImage,
  };
}
