import { createMask } from 'imask';
import { ChangeEvent } from 'react';

interface CustomMasksParams {
  value: string;
  mask: string;
}

export function numberMask(
  event: ChangeEvent<HTMLInputElement>,
): ChangeEvent<HTMLInputElement> {
  const replaceDotsCommas = event.target.value.replace(/\D/g, '');
  const eventInput = event;
  eventInput.target.value = replaceDotsCommas;
  return eventInput;
}

export function unmasked(value: string) {
  const unMask = createMask({ mask: '00000000000000000' });

  unMask.resolve(String(value));

  return unMask.unmaskedValue;
}

export function unmaskedFloat(max: number, value: string) {
  const unMask = createMask({
    mask: Number, // enable number mask
    scale: 2,
    thousandsSeparator: '',
    padFractionalZeros: false,
    normalizeZeros: true,
    radix: ',',
    mapToRadix: ['.'],
    min: 0,
    max,
    autofix: true,
  });

  unMask.resolve(String(value));

  return unMask.unmaskedValue;
}

export function customMask({ value, mask }: CustomMasksParams) {
  const maskFormat = createMask({ mask });
  return maskFormat.resolve(value);
}

export function cepMask(value: string) {
  const cepFormat = createMask({ mask: '00000-000' });
  return cepFormat.resolve(value);
}

export function cpfMask(value: string) {
  const cepFormat = createMask({ mask: '000.000.000-00' });
  return cepFormat.resolve(value);
}

export function cnpjMask(value: string) {
  const cepFormat = createMask({ mask: '00.000.000/0000-00' });
  return cepFormat.resolve(value);
}

export function dateMask(value: string) {
  const dateFormat = createMask({ mask: '00/00/0000' });
  return dateFormat.resolve(value);
}

export function phoneMask(value: string) {
  if (value.length === 13) {
    const telFormat = createMask({ mask: '(00)0000-0000' });
    return telFormat.resolve(value);
  }
  const phoneFormat = createMask({ mask: '(00)00000-0000' });
  return phoneFormat.resolve(value);
}

export function weightMask(value: string, max: number) {
  const percent = createMask({
    mask: Number,
    min: 0,
    max,
    padFractionalZeros: false,
    normalizeZeros: false,
  });

  percent.resolve(value);
  return percent.value;
}

export function weightMaskWithoutDecimals(value: string, max: number) {
  const percent = createMask({
    mask: Number,
    max,
    min: 0,
  });

  percent.resolve(value);
  return percent.value;
}

export function weightMaskPercent(value: string) {
  const percent = createMask({
    lazy: false,
    mask: 'num%',
    blocks: {
      num: {
        mask: Number,
        min: 0,
        max: 100,
      },
    },
  });

  percent.resolve(value);
  return percent.value;
}
