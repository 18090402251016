import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.label`
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;

  input {
    position: absolute;
    opacity: 0;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
  }

  input:checked ~ span {
    background-color: ${colors.blue_light};
  }

  span:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ span:after {
    display: block;
  }

  input:checked:disabled ~ span {
    background: #9c9c9c;
  }

  span:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #f4f4f4;
  }
`;
