import api, { ApiGETRequest, ApiPOSTRequest, APIResponse } from '../Api/api';
import {
  GetNotificationsResponse,
  CreateNotification,
  NotificationDetails,
} from './types';

export async function getNotifications({
  signal,
}: ApiGETRequest): Promise<APIResponse<GetNotificationsResponse>> {
  const res = await api
    .get('/pushNotification', { signal })
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}

export async function getNotificationById({
  params,
  signal,
}: ApiGETRequest<{ id: number }>): Promise<APIResponse<NotificationDetails>> {
  const res = await api
    .get(`/pushNotification/byId/${params.id}`, { signal })
    .then(response => response)
    .catch(err => err.response);
  return res?.data;
}

export async function createPushNotification({
  body,
}: ApiPOSTRequest<CreateNotification>) {
  const res = await api
    .post('pushNotification', body)
    .then(response => response)
    .catch(err => err.response);

  return res.data;
}
