import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';

import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'pt',
    fallbackLng: 'pt',
    backend: {
      loadPath: '/translations/{{lng}}/{{ns}}.json',
    },
    debug: false,
    ns: ['general', 'login', 'navbar'],
    defaultNS: 'general',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: true,
      wait: true,
    },
  });

export default i18n;
