import styled from 'styled-components';
import colors from '../../styles/colors';

interface ContainerProps {
  width: string;
}

export const Container = styled.div<ContainerProps>`
  width: ${p => p.width};
  padding: 0.3rem 0.75rem;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      width: 100%;
      color: ${colors.dark_blue};
      line-height: 0.75rem;
      font-size: 0.75rem;

      > span {
        width: 100%;
      }

      > select {
        /* reset */
        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;

        width: 100%;
        border: 0;
        font-size: 0.87rem;
        line-height: 1.25rem;
        color: #0a0a0a;
        padding: 0.6rem 0.5rem;
        background: ${colors.inputs.background};
        border-radius: 4px;

        background-image: linear-gradient(45deg, transparent 50%, gray 50%),
          linear-gradient(135deg, gray 50%, transparent 50%),
          radial-gradient(#ddd 70%, transparent 72%);
        background-position: right 17px top 50%, right 12.5px top 50%,
          right 10px top 45%;
        background-size: 5px 5px, 5px 5px, 16px 16px;
        background-repeat: no-repeat;

        transition: background-position 0.3s ease;
        :disabled {
          cursor: not-allowed;
        }

        ::placeholder {
          color: #9e9e9e;
        }
      }
    }
  }
`;

export const ErrorMessage = styled.small`
  font-size: 10px;
  min-height: 13px;
  color: ${colors.inputs.errorMessage};
`;
