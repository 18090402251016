import { useCallback, useEffect, useState } from 'react';

let countdownTimeout: NodeJS.Timeout;

export default function useCountdown() {
  const [time, setTime] = useState(2 * 60);
  const [isActive, setIsActive] = useState(false);
  const [hasFinished, setHasFinished] = useState(false);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const startCountdown = useCallback(() => {
    setIsActive(true);
  }, []);
  const resetTimer = useCallback(() => {
    setIsActive(true);
  }, []);

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
      clearTimeout(countdownTimeout);
      setIsActive(false);
      setTime(2 * 60);
      setHasFinished(false);
    }
  }, [minutes, seconds]);

  useEffect(() => {
    if (isActive && time > 0) {
      countdownTimeout = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    } else if (isActive && time === 0) {
      setHasFinished(true);
      setIsActive(false);
    }
  }, [isActive, time]);

  return {
    minutes,
    seconds,
    hasFinished,
    isActive,
    startCountdown,
    resetTimer,
  };
}
