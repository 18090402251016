import { Box, CircularProgress } from '@mui/material';

export default function Fallback() {
  return (
    <Box
      width="100%"
      height="80vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      fontSize="22px"
      padding="0"
      margin="0"
      fontWeight={600}
    >
      <CircularProgress color="info" size={50} />
    </Box>
  );
}
