import { toast } from 'react-toastify';

/** Cria um Toast de sucesso com a mensagem indicada */
export function successToast(message: string) {
  toast.success(message);
}

/** Cria um Toast de erro com a mensagem indicada */
export function errorToast(message: string) {
  toast.error(message);
}

export function infToast(message: string) {
  toast.info(message);
}
