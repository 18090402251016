import styled from 'styled-components';
import colors from '../../styles/colors';

interface ProfileContentProps {
  open: boolean;
}

interface ViewNotification {
  view: number;
}

export const Container = styled.div`
  width: 100%;
  height: 50px;
  background: linear-gradient(
    180deg,
    #313277 0%,
    rgba(40, 76, 143, 0.994792) 100%
  );

  position: fixed;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.75rem;
  z-index: 2;

  button {
    color: #fff;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  margin-right: 1rem;

  ul {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px;
      padding: 0px;

      > button {
        font-weight: normal !important;
        color: #222222 !important;
        height: 50px !important;
      }
    }
  }
`;

export const LanguageContent = styled.div`
  display: inline-block;
  align-items: center;
  position: relative;
  height: 100%;
`;

export const ProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  margin-right: 0.5rem;
`;

export const Data = styled.div`
  display: flex;
  align-items: center;

  > div > button {
    max-width: 200px;
    color: #fff !important;
    :hover {
      background: #30357a !important;
    }
  }

  p {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 100%;
  }
`;

export const ProfileOptions = styled.nav<ProfileContentProps>`
  position: absolute;
  display: ${props => (props.open ? 'show' : 'none')};
  background: #fff;
  width: 140px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  transition: all 0.5s;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transform: translateY(47px);

  div:last-child {
    > button {
      color: #ed4c4c !important;

      :hover {
        background: #ffcdd2 !important;
      }
    }
  }
`;

export const LanguageOptions = styled.nav<ProfileContentProps>`
  position: absolute;
  display: ${props => (props.open ? 'show' : 'none')};
  background: #fff;
  width: 90%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  transition: all 0.5s;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transform: translateX(10px);

  img {
    width: 32px;
    margin-right: 5px;
  }

  ul {
    display: flex;
    flex-direction: column;

    button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 0.5rem;
      color: #222222;
      font-weight: normal;
      font-size: 0.9rem;
      height: 50px;
    }

    button:hover {
      background: #eee;
    }
  }
`;

export const Notifications = styled.div`
  display: inline-block;
  align-items: center;
  height: 100%;
  button {
    height: 50px;
  }
`;

export const NotificationOptions = styled.nav<ProfileContentProps>`
  position: absolute;
  display: ${props => (props.open ? 'block' : 'none')};
  background: #fff;
  transition: all 0.5s;
  border-radius: 0 0 4px 4px;
  width: 250px;
  transform: translateX(-95%);
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);

  > ul {
    max-height: 200px;

    & p {
      line-height: 55px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      height: 55px;
      text-align: start;
      width: 100%;
    }

    ::-webkit-scrollbar {
      width: 15px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 15px grey;
      border-radius: 4px;
      background-color: #ededed;
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.dark_blue};
      border-radius: 4px;
    }

    overflow-y: scroll;
  }
  img {
    width: 36px;
  }

  p {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 0 0.6rem;
  }
`;

export const NotificationHeader = styled.div`
  padding: 0.1rem 0.3rem 0.1rem 0.7rem;
  background-color: white;
  border-bottom: 3px solid #d9d9d9;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.dark_blue};

  span {
    font-weight: 600;
    font-size: 17px;
  }

  svg {
    fill: ${colors.dark_blue};
  }
`;

export const Circle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 100%;
  position: absolute;
  top: 24px;
  margin-left: 12px;
  background-color: ${colors.dark_blue};
  font-size: 10px;
  color: #fff;
  padding-top: 1px;
`;

export const ButtonNotification = styled.button<ViewNotification>`
  display: flex;
  align-items: center;
  background: ${p => (p.view === 1 ? '#d3d3d3' : '#ffffff')};
  :hover {
    background: ${p => (p.view === 1 ? '#c1c1c1' : '#ededed')};
  }
  p {
    color: ${p => (p.view === 1 ? '#555' : '#222')};
  }
`;
