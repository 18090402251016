import styled from 'styled-components';
import colors from '../../styles/colors';

interface SelectProps {
  width: string;
}

export const Container = styled.div<SelectProps>`
  width: ${p => p.width};
  padding: 0.3rem 0.75rem;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
      width: 100%;
      line-height: 0.75rem;
      color: ${colors.dark_blue};
      font-size: 0.75rem;

      > span {
        width: 100%;
      }

      > select {
        width: 100%;
        border: 0;
        font-size: 0.87rem;
        line-height: 1.25rem;
        color: #0a0a0a;
        padding: 0.5rem 0.5rem;
        background: ${colors.inputs.background};
        border-radius: 4px;
        position: relative;

        ::placeholder {
          color: #9e9e9e;
        }
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;

        svg {
          width: 30px;
        }
      }
    }
  }
`;

export const ErrorMessage = styled.small`
  font-size: 10px;
  min-height: 13px;
  color: ${colors.inputs.errorMessage};
`;
