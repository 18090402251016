import 'mapbox-gl/dist/mapbox-gl.css';
import { Suspense } from 'react';

import { AuthProvider } from './contexts/authContext';
import './i18n';
import Routes from './routes';
import Fallback from './routes/Fallback';
import GlobalStyle, { StyledToastContainer } from './styles/global';

export default function App() {
  return (
    <Suspense fallback={<Fallback />}>
      <GlobalStyle />
      <AuthProvider>
        <Routes />
      </AuthProvider>
      <StyledToastContainer autoClose={8000} />
    </Suspense>
  );
}
