import { Container } from './styles';

interface CountdownProps {
  width?: string;
  minutes: number;
  seconds: number;
}

export default function Countdown({
  width = '100%',
  minutes,
  seconds,
}: CountdownProps) {
  const [minuteLeft, minuteRight] = String(minutes).padStart(2, '0').split('');
  const [secondLeft, secondRight] = String(seconds).padStart(2, '0').split('');

  return (
    <Container width={width}>
      <span>{minuteLeft}</span>
      <span>{minuteRight}</span>
      <span>:</span>
      <span>{secondLeft}</span>
      <span>{secondRight}</span>
    </Container>
  );
}
