import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button';
import Modal from '../../Modal';
import { Footer } from '../../Modal/styles';
import { getNotificationById } from '../../../services/Notification';
import { NotificationDetails } from '../../../services/Notification/types';

interface NotificationDetailsModalProps {
  notificationId: number;
  onClose: () => void;
}

export default function NotificationDetailsModal({
  notificationId,
  onClose,
}: NotificationDetailsModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [buttonText, setButtonText] = useState('');

  const [notificationDetails, setNotificationDetails] =
    useState<NotificationDetails>({
      pus_body: '',
      pus_id: -1,
      pus_title: '',
      pus_type: -1,
      pus_type_id: -1,
    });

  const viewMaintenance = useCallback(() => {
    if (notificationDetails.pus_type === 1) {
      navigate('/chip');
      onClose();
      return;
    }

    if (notificationDetails.pus_type === 2) {
      navigate('/incidents');
      onClose();
      return;
    }

    if (notificationDetails.pus_type === 3) {
      navigate('/dash');
      onClose();
      return;
    }

    if (
      notificationDetails.pus_type === 4 ||
      notificationDetails.pus_type === 102
    ) {
      navigate(`/parts/edit/${notificationDetails.pus_type_id}`);
      onClose();
      return;
    }

    if (
      notificationDetails.pus_type === 5 ||
      notificationDetails.pus_type === 101
    ) {
      navigate(`/equipment/edit/${notificationDetails.pus_type_id}`);
      onClose();
    }
  }, [navigate, notificationDetails, onClose]);

  useEffect(() => {
    if (notificationDetails.pus_type === 2) {
      setButtonText(t('view-occurrence'));
    } else if (
      notificationDetails.pus_type === 101 ||
      notificationDetails.pus_type === 102
    ) {
      setButtonText(t('view-checklist'));
    } else {
      setButtonText(t('view-maintenance'));
    }
  }, [t, notificationDetails]);

  useEffect(() => {
    const getNotification = async (id: number, signal: AbortSignal) => {
      const response = await getNotificationById({ params: { id }, signal });

      if (response.error === 0) {
        setNotificationDetails(response.data);
      }
      setIsLoading(false);
    };

    const controller = new AbortController();
    getNotification(notificationId, controller.signal);

    return () => {
      controller.abort();
    };
  }, [notificationId]);

  return (
    <Modal maxWidth="500px" onClick={onClose} title="Manu 4.0 Base">
      {!isLoading ? (
        <>
          <div style={{ padding: '36px 50px' }}>
            {notificationDetails.pus_body}
          </div>
          <Footer>
            {notificationDetails.pus_type &&
              notificationDetails.pus_type !== 18 && (
                <Button type="button" width="200px" onClick={viewMaintenance}>
                  {buttonText}
                </Button>
              )}
            <Button type="button" width="125px" onClick={onClose}>
              {t('generic-button-close')}
            </Button>
          </Footer>
        </>
      ) : (
        <div
          style={{
            width: '400px',
            height: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="info" size={50} />
        </div>
      )}
    </Modal>
  );
}
