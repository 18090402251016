import { CSSProperties, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { useOnClickOutside } from '../../utils/hooks';
import { ContainerAround, Overlay, Title } from './styles';

interface ModalProps {
  children: ReactNode;
  maxWidth?: string;
  title?: string;
  onClick: () => void;
  disableClickOutside?: boolean;
  width?: string;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  height?: string;
}

export default function Modal({
  children,
  maxWidth = '100%',
  width,
  title,
  onClick,
  disableClickOutside = false,
  style = {},
  contentStyle = {},
  height,
}: ModalProps) {
  const { t } = useTranslation();
  const modalRef = useRef(null);

  useOnClickOutside(modalRef, disableClickOutside ? () => null : onClick);

  return (
    <Overlay>
      <ContainerAround
        height={height}
        width={width}
        maxWidth={maxWidth}
        ref={modalRef}
        style={style}
      >
        <Title>
          {t(`${title}`)}
          <FaTimes size={24} onClick={onClick} />
        </Title>
        <div
          style={{
            padding: '0 2rem',
            overflowY: 'hidden',
            ...contentStyle,
          }}
        >
          {children}
        </div>
      </ContainerAround>
    </Overlay>
  );
}
