import { lazy, Suspense, useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAuth, User } from '../contexts/authContext';
import Fallback from './Fallback';
import AppLayout from './Layout';
import PrivateRoute from './PrivateRoute';

const Login = lazy(() => import('../pages/Login'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Company = lazy(() => import('../pages/Company'));

const ChipDetails = lazy(() => import('../core/Chip/components/ChipDetails'));
const EditCompany = lazy(
  () => import('../core/Companies/ListOfCompanies/EditCompany'),
);
const EditParts = lazy(() => import('../core/Parts/EditParts'));
const NewProduct = lazy(() => import('../core/Shop/components/AddProduct'));
const DetailsProduct = lazy(
  () => import('../core/Shop/components/DetailsProduct'),
);
const Chip = lazy(() => import('../pages/Chip'));
const Contact = lazy(() => import('../pages/Contact'));
const ContactPage = lazy(() => import('../pages/ContactPage'));
const Equipments = lazy(() => import('../pages/Equipments'));
const EditEquipment = lazy(() => import('../pages/Equipments/EditEquipment'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const MachineDowntime = lazy(() => import('../pages/MachineDowntime'));
const Map = lazy(() => import('../pages/Map'));
const NotAuthorized = lazy(() => import('../pages/NotAuthorized'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Occurrences = lazy(() => import('../pages/Occurrences'));
const Parts = lazy(() => import('../pages/Parts'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const Providers = lazy(() => import('../pages/Providers'));
const Settings = lazy(() => import('../pages/Settings'));
const Shop = lazy(() => import('../pages/Shop'));
const Unit = lazy(() => import('../pages/Unit'));
const UserPage = lazy(() => import('../pages/UserPage'));

const Checklists = lazy(() => import('../pages/Checklists'));
const ViewChecklist = lazy(() => import('../core/Checklists/ViewChecklist'));
const ViewBindings = lazy(() => import('../core/Checklists/ViewBindings'));
const ChecklistSubmissions = lazy(
  () => import('../core/Checklists/ChecklistSubmissions'),
);
const CreateChecklist = lazy(
  () => import('../core/Checklists/CreateChecklist'),
);
const EditChecklist = lazy(() => import('../core/Checklists/EditChecklist'));
const CreateBindings = lazy(() => import('../core/Checklists/CreateBindings'));

const EditBindings = lazy(() => import('../core/Checklists/EditBindings'));
const Logs = lazy(() => import('../pages/Logs'));
const TrendChat = lazy(() => import('../core/TrendChart'));

export default function AppRoutes() {
  const { hasAccess, getUser } = useAuth();

  const user = useMemo<Partial<User> | null>(() => getUser(), [getUser]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Fallback />}>
              <Navigate to="/login" />
            </Suspense>
          }
        />

        {/*  layout com padding */}
        <Route element={<AppLayout withPadding />}>
          <Route
            path="/companies"
            element={
              <PrivateRoute isAllowed={hasAccess('list_company')}>
                <Company />
              </PrivateRoute>
            }
          />
          <Route
            path="/Equipment/edit/:id"
            element={
              <PrivateRoute isAllowed={hasAccess('list_machine')}>
                <EditEquipment />
              </PrivateRoute>
            }
          />
          <Route
            path="/parts/edit/:com_agr_code"
            element={
              <PrivateRoute isAllowed={hasAccess('update_component')}>
                <EditParts />
              </PrivateRoute>
            }
          />
          <Route
            path="/company/edit/:cmp_id"
            element={
              <PrivateRoute isAllowed={hasAccess('update_company')}>
                <EditCompany />
              </PrivateRoute>
            }
          />
          <Route
            path="/chip/informations/:id"
            element={
              <PrivateRoute isAllowed={hasAccess('update_chip')}>
                <ChipDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/shop"
            element={
              <PrivateRoute isAllowed={!!user}>
                <Shop />
              </PrivateRoute>
            }
          />
          <Route
            path="/details-product/:id"
            element={
              <PrivateRoute isAllowed={!!user}>
                <DetailsProduct />
              </PrivateRoute>
            }
          />
          <Route
            path="/product/:id?"
            element={
              <PrivateRoute
                isAllowed={hasAccess('create_market_item')}
                redirectPath="/shop"
              >
                <NewProduct />
              </PrivateRoute>
            }
          />

          <Route
            path="/stopped-machine"
            element={
              <PrivateRoute isAllowed={!!user}>
                <MachineDowntime />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute isAllowed={!!user}>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/chip"
            element={
              <PrivateRoute
                isAllowed={
                  hasAccess('list_chip') ||
                  hasAccess('list_maintenance') ||
                  hasAccess('list_lot')
                }
              >
                <Chip />
              </PrivateRoute>
            }
          />
          <Route
            path="/parts"
            element={
              <PrivateRoute isAllowed={hasAccess('list_component')}>
                <Parts />
              </PrivateRoute>
            }
          />
          <Route
            path="/incidents"
            element={
              <PrivateRoute isAllowed={hasAccess('list_occurrence')}>
                <Occurrences />
              </PrivateRoute>
            }
          />
          <Route
            path="/provider"
            element={
              <PrivateRoute isAllowed={hasAccess('list_provider')}>
                <Providers />
              </PrivateRoute>
            }
          />
          <Route
            path="/Equipment"
            element={
              <PrivateRoute isAllowed={hasAccess('list_machine')}>
                <Equipments />
              </PrivateRoute>
            }
          />
          <Route
            path="/unit"
            element={
              <PrivateRoute
                isAllowed={hasAccess('list_unit' || 'list_localwork')}
              >
                <Unit />
              </PrivateRoute>
            }
          />
          <Route
            path="/contact"
            element={
              <PrivateRoute isAllowed={!!user} redirectPath="/unauthorized">
                <Contact />
              </PrivateRoute>
            }
          />

          <Route
            path="/checklists/bind/edit"
            element={
              <PrivateRoute isAllowed={hasAccess('add_checklist')}>
                <EditBindings />
              </PrivateRoute>
            }
          />

          <Route
            path="/checklists/bind"
            element={
              <PrivateRoute isAllowed={hasAccess('add_checklist')}>
                <CreateBindings />
              </PrivateRoute>
            }
          />

          <Route
            path="/checklists/add"
            element={
              <PrivateRoute isAllowed={hasAccess('add_checklist')}>
                <CreateChecklist />
              </PrivateRoute>
            }
          />
          <Route
            path="/checklists/edit"
            element={
              <PrivateRoute isAllowed={hasAccess('add_checklist')}>
                <EditChecklist />
              </PrivateRoute>
            }
          />
          <Route
            path="/checklists/bind/view"
            element={
              <PrivateRoute isAllowed={hasAccess('add_checklist')}>
                <ViewBindings />
              </PrivateRoute>
            }
          />
          <Route
            path="/checklists/view"
            element={
              <PrivateRoute isAllowed={hasAccess('add_checklist')}>
                <ViewChecklist />
              </PrivateRoute>
            }
          />
          <Route
            path="/checklists/submission"
            element={
              <PrivateRoute isAllowed={hasAccess('add_checklist')}>
                <ChecklistSubmissions />
              </PrivateRoute>
            }
          />
          <Route
            path="/checklists"
            element={
              <PrivateRoute isAllowed={!!user}>
                <Checklists />
              </PrivateRoute>
            }
          />
          <Route
            path="/logs"
            element={
              <PrivateRoute isAllowed={!!user}>
                <Logs />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute isAllowed={!!user}>
                <UserPage />
              </PrivateRoute>
            }
          />
          <Route
            path="trend_chart"
            element={
              <PrivateRoute
                isAllowed={
                  hasAccess('list_chip') ||
                  hasAccess('list_maintenance') ||
                  hasAccess('list_lot')
                }
              >
                <TrendChat />
              </PrivateRoute>
            }
          />
        </Route>

        {/* layout sem padding */}
        <Route element={<AppLayout />}>
          <Route
            path="/dash"
            element={
              <PrivateRoute isAllowed={!!user} redirectPath="/login">
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/map"
            element={
              <PrivateRoute isAllowed={!!user}>
                <Map />
              </PrivateRoute>
            }
          />
        </Route>

        {/* sem layout */}
        <Route>
          <Route
            path="/login"
            element={
              <PrivateRoute isAllowed={!user} redirectPath="/dash">
                <Login />
              </PrivateRoute>
            }
          />
          <Route
            path="/forgotPassword/:token"
            element={
              <Suspense fallback={<Fallback />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path="/privacyPolicy"
            element={
              <Suspense fallback={<Fallback />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path="/suporte"
            element={
              <Suspense fallback={<Fallback />}>
                <ContactPage />
              </Suspense>
            }
          />
          <Route
            path="/unauthorized"
            element={
              <Suspense fallback={<Fallback />}>
                <NotAuthorized />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/"
          element={
            <Suspense fallback={<Fallback />}>
              <Navigate to="/login" />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<Fallback />}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
