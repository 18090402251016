import styled from 'styled-components';
import colors from '../../../styles/colors';

export const FilterContainer = styled.div`
  margin-bottom: 0.5rem;

  input {
    border: 2px solid ${colors.gray};
    padding: 0.5rem;
    border-radius: 8px;

    &:focus {
      border: 2px solid ${colors.blue_light};
      transition: 0.2s;
    }
  }
`;
