import styled from 'styled-components';
import colors from '../../styles/colors';

export const TablePrimal = styled.table`
  width: 100%;

  border-spacing: 0px;
  border-collapse: collapse;
`;

export const Tbody = styled.tbody`
  font-size: 14px;

  tr:nth-child(even) {
    background: ${colors.gray};
  }

  td:first-child {
    text-align: left;
  }

  tr {
    transition: all 0.2s ease-in;
    text-align: center;
    cursor: pointer;
  }

  td {
    transition: all 0.2s ease-in;
    padding: 8px 16px;
    border: 1px solid #fff;
  }

  tr:hover {
    background-color: #d4f5ff;
  }
`;

export const DeleteAction = styled.td`
  text-align: center;
  background: #fff;
  width: 30px;
  padding: 5px !important;
  svg {
    color: #ed4c4c;
  }
`;

export const Report = styled.td`
  text-align: center;
  background: #fff;
  width: 30px;
  padding: 5px !important;
  svg {
    color: ${colors.dark_blue};
  }
`;

export const BarcodeAction = styled.td`
  text-align: center;
  background: #fff;
  svg {
    color: #666666;
    :hover {
      color: #222222;
    }
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Thead = styled.thead`
  th {
    padding: 10px;
    font-size: 15px;
    border-bottom: 3px solid #00c4ff;
    background: #e8e8e8;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.7);
  }

  th:first-child {
    padding-left: 1rem;
    text-align: left;
  }
`;

export const DivTable = styled.div`
  width: 100%;
  border: 1px solid rgb(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
`;

export const Deleted = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  transition: all 0.2s ease-in;
  svg {
    transition: all 0.2s ease-in;
  }
  :hover {
    background: ${colors.red_strong};
    svg {
      color: ${colors.white};
    }
  }
`;

export const ReportIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  transition: all 0.2s ease-in;
  svg {
    transition: all 0.2s ease-in;
  }
  :hover {
    background: ${colors.dark_blue};
    svg {
      color: ${colors.white};
    }
  }
`;

export const Edit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: all 0.2s ease-in;
  width: 35px;
  height: 35px;

  svg {
    transition: all 0.2s ease-in;
    color: ${colors.blue_light};
  }
  :hover {
    background: ${colors.blue_light};
    svg {
      color: ${colors.white};
    }
  }
`;

export const Tags = styled.div`
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
  }
  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: #e9e9e9;
  }

  input[type='checkbox']:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: ${colors.dark_blue};
  }
  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid #e9e9e9;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }
`;
