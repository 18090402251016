import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Fallback from './Fallback';

interface PrivateRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
  children?: JSX.Element;
}

export default function PrivateRoute({
  isAllowed,
  redirectPath = '/login',
  children = <Outlet />,
}: PrivateRouteProps) {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Suspense fallback={<Fallback />}>{children}</Suspense>;
}
