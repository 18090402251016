import { colors } from '@mui/material';
import styled, { css } from 'styled-components';

interface TabProps {
  active: boolean;
}

export const Container = styled.nav`
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  span + span {
    margin-left: 1rem;
  }
`;

export const Option = styled.span<TabProps>`
  color: ${props => (props.active ? colors.cyan[200] : colors.cyan[50])};
  cursor: pointer;
  transition: all 0.2s ease-in;
  line-height: 1.5rem;
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.active &&
    css`
      border-bottom: 2px solid ${colors.cyan[200]};
    `}

  &:hover {
    color: ${props => (props.active ? colors.cyan[400] : colors.cyan[200])};
  }
`;
