import styled from 'styled-components';
import colors from '../../styles/colors';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 2rem;
  margin-top: 12px;
`;

export const ContentPdf = styled.a`
  text-decoration: none;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${colors.red_strong};
    padding: 1rem;
    border-radius: 15px;
    transition: 0.2s;
    color: ${colors.red_strong};
    font-weight: bold;
    :hover {
      background: ${colors.red_strong};
      color: #fff;
      svg {
        color: #fff;
      }
      cursor: pointer;
    }
  }
  svg {
    transition: 0.2s;
    margin-bottom: 16px;
    color: ${colors.red_strong};
  }
`;

export const ContentExcel = styled.a`
  text-decoration: none;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #016f38;
    padding: 1rem;
    border-radius: 15px;
    transition: 0.2s;
    color: #016f38;
    font-weight: bold;
    :hover {
      background: #016f38;
      color: #fff;
      svg {
        color: #fff;
      }
      cursor: pointer;
    }
  }
  svg {
    transition: 0.2s;
    margin-bottom: 16px;
    color: #016f38;
  }
`;
