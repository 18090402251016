import styled, { keyframes } from 'styled-components';

export const DetailsContainer = styled.details`
  width: 100%;
  border: 1px solid hsl(0, 0%, 90%);
  padding: 1.5rem;
`;

export const SummaryWrapper = styled.summary`
  display: flex;
  justify-content: flex-end;
`;

export const SummaryContainer = styled.div`
  display: flex;
  width: 100%;
`;

const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

export const ChildrenContainer = styled.div<{ isOpen: boolean }>`
  animation: 0.5s ease-out;
  animation-name: ${props => (props.isOpen ? slideDown : slideUp)};
`;

export const DetailsButton = styled.button<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  margin: auto 1rem;

  :hover {
    cursor: pointer;
  }

  svg {
    transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};

    transition: all 0.5s;
  }
`;
