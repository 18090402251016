import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BiMapPin } from 'react-icons/bi';
import { BsDiagram3, BsGraphUp, BsShop } from 'react-icons/bs';
import { FaBoxes, FaList, FaRegBuilding, FaTractor } from 'react-icons/fa';
import { GiMicrochip } from 'react-icons/gi';
import { GrMap } from 'react-icons/gr';
import { IoMdMenu } from 'react-icons/io';
import {
  MdErrorOutline,
  MdOutlineTimer,
  MdSettingsInputComponent,
} from 'react-icons/md';
import { RiContactsBook2Line, RiLogoutBoxLine } from 'react-icons/ri';
import { TbChecklist } from 'react-icons/tb';
import { VscGraph } from 'react-icons/vsc';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { useOnClickOutside } from '../../utils/hooks';
import {
  Container,
  ContainerLogo,
  ContainerUl,
  Icon,
  LogoContent,
  ProfileContent,
  SidebarItem,
  Tool,
} from './styles';

type SidebarProps = {
  navbarOpen: boolean;
  setNavbarOpen: Dispatch<SetStateAction<boolean>>;
};

export default function Sidebar({ navbarOpen, setNavbarOpen }: SidebarProps) {
  const { t } = useTranslation();

  const navRef = useRef(null);
  const { handleLogout, hasAccess } = useAuth();

  const navigate = useNavigate();
  const route = useParams();
  const location = useLocation();

  const handleRedirect = (path: string) => {
    navigate(`/${path}`);
  };

  const onHandleClick = () => {
    setNavbarOpen(prev => !prev);
  };

  useOnClickOutside(navRef, () => setNavbarOpen(false));

  useEffect(() => {
    setNavbarOpen(false);
  }, [route, setNavbarOpen]);

  return (
    <Container active={navbarOpen} ref={navRef}>
      <ContainerLogo>
        <LogoContent onClick={onHandleClick} active={navbarOpen}>
          <img
            src={`${process.env.PUBLIC_URL}/images/manu40blue.png`}
            alt="MANU 4.0"
            width={150}
          />
          <IoMdMenu />
        </LogoContent>
      </ContainerLogo>
      <ContainerUl>
        <ul>
          {hasAccess('dashboard_access') && (
            <SidebarItem
              active={location.pathname === '/dash'}
              onClick={() => handleRedirect('dash')}
            >
              <div>
                <Icon>
                  <VscGraph />
                </Icon>
                <span>{t('navbar-dash-panel')}</span>
              </div>
              <Tool active={navbarOpen}>{t('navbar-dash-panel')}</Tool>
            </SidebarItem>
          )}
          {hasAccess('list_company') && (
            <SidebarItem
              active={location.pathname === '/companies'}
              onClick={() => handleRedirect('companies')}
            >
              <div>
                <Icon>
                  <FaRegBuilding />
                </Icon>
                <span>{t('navbar-company.label')}</span>
              </div>
              <Tool active={navbarOpen}>{t('navbar-company.label')}</Tool>
            </SidebarItem>
          )}
          <SidebarItem
            active={location.pathname === '/parts'}
            onClick={() => handleRedirect('parts')}
          >
            <div>
              <Icon>
                <MdSettingsInputComponent />
              </Icon>
              <span>{t('navbar-parts')}</span>
            </div>

            <Tool active={navbarOpen}>{t('navbar-parts')}</Tool>
          </SidebarItem>
          {hasAccess('list_occurrence') && (
            <SidebarItem
              active={location.pathname === '/incidents'}
              onClick={() => handleRedirect('incidents')}
            >
              <div>
                <Icon>
                  <MdErrorOutline />
                </Icon>
                <span>{t('navbar-occurrence')}</span>
              </div>
              <Tool active={navbarOpen}>{t('navbar-occurrence')}</Tool>
            </SidebarItem>
          )}
          {hasAccess('list_chip') ||
          hasAccess('list_maintenance') ||
          hasAccess('list_lot') ? (
            <SidebarItem
              active={location.pathname === '/chip'}
              onClick={() => handleRedirect('chip')}
            >
              <div>
                <Icon>
                  <GiMicrochip size={18} />
                </Icon>
                <span>Chips</span>
              </div>
              <Tool active={navbarOpen}>Chips</Tool>
            </SidebarItem>
          ) : (
            <></>
          )}
          {hasAccess('list_provider') && (
            <SidebarItem
              active={location.pathname === '/provider'}
              onClick={() => handleRedirect('provider')}
            >
              <div>
                <Icon>
                  <FaBoxes />
                </Icon>
                <span>{t('company-parts-modal-providers')}</span>
              </div>
              <Tool active={navbarOpen}>
                {t('company-parts-modal-providers')}
              </Tool>
            </SidebarItem>
          )}
          {hasAccess('list_machine') && (
            <SidebarItem
              active={location.pathname === '/equipment'}
              onClick={() => handleRedirect('equipment')}
            >
              <div>
                <Icon>
                  <FaTractor />
                </Icon>
                <span>{t('company-tab-equip')}</span>
              </div>
              <Tool active={navbarOpen}>{t('company-tab-equip')}</Tool>
            </SidebarItem>
          )}

          {hasAccess('list_machine') && (
            <SidebarItem
              active={location.pathname === '/trend_chart'}
              onClick={() => handleRedirect('trend_chart')}
            >
              <div>
                <Icon>
                  <BsGraphUp />
                </Icon>
                <span>Gráfico Tendência</span>
              </div>
              <Tool active={navbarOpen}>{t('company-tab-equip')}</Tool>
            </SidebarItem>
          )}
          <SidebarItem
            active={location.pathname === '/checklists'}
            onClick={() => handleRedirect('checklists')}
          >
            <div>
              <Icon>
                <TbChecklist />
              </Icon>
              <span>Checklists</span>
            </div>
            <Tool active={navbarOpen}>Checklists</Tool>
          </SidebarItem>
          <SidebarItem
            active={location.pathname === '/shop'}
            onClick={() => handleRedirect('shop')}
          >
            <div>
              <Icon>
                <BsShop />
              </Icon>
              <span>{t('shop-product')}</span>
            </div>
            <Tool active={navbarOpen}>{t('shop-product')}</Tool>
          </SidebarItem>
          {hasAccess('list_unit') || hasAccess('list_localwork') ? (
            <SidebarItem
              active={location.pathname === '/unit'}
              onClick={() => handleRedirect('unit')}
            >
              <div>
                <Icon>
                  <BiMapPin />
                </Icon>
                <span>{t('company-equipment-modal-unit')}</span>
              </div>
              <Tool active={navbarOpen}>
                {t('company-equipment-modal-unit')}
              </Tool>
            </SidebarItem>
          ) : (
            <></>
          )}
          <SidebarItem
            active={location.pathname === '/stopped-machine'}
            onClick={() => handleRedirect('stopped-machine')}
          >
            <div>
              <Icon>
                <MdOutlineTimer />
              </Icon>
              <span>{t('company-equipment-stopped')}</span>
            </div>
            <Tool active={navbarOpen}>{t('company-equipment-stopped')}</Tool>
          </SidebarItem>
          <SidebarItem
            active={location.pathname === '/contact'}
            onClick={() => handleRedirect('contact')}
          >
            <div>
              <Icon>
                <RiContactsBook2Line />
              </Icon>
              <span>{t('company-tab-contact')}</span>
            </div>
            <Tool active={navbarOpen}>{t('company-tab-contact')}</Tool>
          </SidebarItem>
          <SidebarItem
            active={location.pathname === '/settings'}
            onClick={() => handleRedirect('settings')}
          >
            <div>
              <Icon>
                <BsDiagram3 />
              </Icon>
              <span>{t('settings.page-name')}</span>
            </div>
            <Tool active={navbarOpen}>{t('settings.page-name')}</Tool>
          </SidebarItem>
          <SidebarItem
            active={location.pathname === '/map'}
            onClick={() => handleRedirect('map')}
          >
            <div>
              <Icon>
                <GrMap />
              </Icon>
              <span>{t('navbar-map')}</span>
            </div>
            <Tool active={navbarOpen}>{t('navbar-map')}</Tool>
          </SidebarItem>
          <SidebarItem
            active={location.pathname === '/logs'}
            onClick={() => handleRedirect('logs')}
          >
            <div>
              <Icon>
                <FaList />
              </Icon>
              <span>{t('logs')}</span>
            </div>
            <Tool active={navbarOpen}>{t('logs')}</Tool>
          </SidebarItem>
        </ul>
      </ContainerUl>

      <ProfileContent active={navbarOpen}>
        <div>
          <div>
            <div>Manu 4.0 Base</div>
          </div>
        </div>
        <button type="button" onClick={handleLogout}>
          <RiLogoutBoxLine />
          <Tool active={navbarOpen}>{t('logout-text')}</Tool>
        </button>
      </ProfileContent>
    </Container>
  );
}
