import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import React from 'react';
/**
 * Recebe um evento e um decimal  para formatação das casas decimais.
 * Formata para a BRL (R$).
 */
export default function parseBRL(
  event: React.ChangeEvent<HTMLInputElement>,
  decimal: number,
): string {
  const replaceDotsCommas = event.target.value
    .replace(/\D/g, '')
    .replace('.', '')
    .replace(',', '.');
  const replaceToNumber =
    replaceDotsCommas.length <= 0
      ? 0
      : Number(replaceDotsCommas) / 10 ** decimal;
  const field = { ...event };

  field.target.value = formatCurrency(replaceToNumber, {
    precision: decimal,
  });

  return field.target.value;
}
